import { Box, BoxProps, Divider, ITheme, Link, styled, Container } from '@material-ui/core';
import { ReactComponent as FacebookIcon } from '../../images/facebook-icon.svg';
import { ReactComponent as LinkedInIcon } from '../../images/linkedin-icon.svg';
import { ReactComponent as TwitterIcon } from '../../images/twitter-icon.svg';
import { ReactComponent as InstagramIcon } from '../../images/instagram-icon.svg';
import Flex from '../Common/Flex';
import { Large } from '../Common/Text';
import { SocialsType } from './types';
import { useAppClips } from './Context';

const getSocialIcon = (type: SocialsType): React.ReactElement | undefined => {
  switch (type) {
    case SocialsType.FACEBOOK:
      return <FacebookIcon />;
    case SocialsType.INSTAGRAM:
      return <InstagramIcon />;
    case SocialsType.LINKED_IN:
      return <LinkedInIcon />;
    case SocialsType.TWITTER:
      return <TwitterIcon />;
    default:
      return undefined;
  }
};

const FooterLinksContainer = styled(Flex)(({ theme }: { theme: ITheme }) => ({
  minWidth: 212,
  borderTop: `1px solid ${theme.palette.borders?.divider}`,
  borderBottom: `1px solid ${theme.palette.borders?.divider}`,
  paddingTop: theme.spacing(0.625),
  paddingBottom: theme.spacing(0.625),
  justifyContent: 'space-evenly',
  marginBottom: theme.spacing(1.5),
}));

const Footer = (props: BoxProps): React.ReactElement => {
  const { merchantContent } = useAppClips();
  return (
    <Container>
      <Flex flexDirection="column" alignItems="center" justifyContent="center" pb={3} {...props}>
        <Box mb={1.5}>
          <img src={merchantContent.data.brandLogoUrl} alt={merchantContent.name} style={{ maxWidth: 83.03 }} />
        </Box>
        <Box component={Divider} maxWidth={212} />
        <FooterLinksContainer>
          <Link href={merchantContent.data.termsAndConditionsUrl} target="_blank" underline="none">
            <Large color="bodyTextColor" my={0}>
              Terms
            </Large>
          </Link>
          <Link href={merchantContent.data.privacyUrl} target="_blank" underline="none">
            <Large color="bodyTextColor" my={0}>
              Privacy
            </Large>
          </Link>
          <Link href={merchantContent.data.contactUsUrl} target="_blank" underline="none">
            <Large color="bodyTextColor" my={0}>
              Contact
            </Large>
          </Link>
        </FooterLinksContainer>
        <Flex alignItems="center" mb={{ xs: 3, md: 8 }}>
          {merchantContent.data.socials?.map((item, i) => (
            <Link href={item.url} underline="none" target="_blank" key={item.type}>
              <Box ml={i === 0 ? 0 : 1.5} color="bodyTextColor">
                {getSocialIcon(item.type)}
              </Box>
            </Link>
          ))}
        </Flex>
        <Large my={0} color="bodyTextColor" textAlign="center">
          {merchantContent.data.copyright}
        </Large>
        <Large my={0} color="bodyTextColor" textAlign="center">
          Apple Pay, Apple Watch, Face ID, iPhone are trademarks of Apple Inc., registered in the U.S. and other
          countries.
        </Large>
      </Flex>
    </Container>
  );
};

export default Footer;
