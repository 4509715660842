import { Box, Button, Container, Link } from '@material-ui/core';
import { Hidden } from '@mui/material';
import ApplePayLogo from '../../images/apple-pay-logo.svg';
import GPayLogo from '../../images/g-pay-logo.png';
import BannerContainer from '../Common/BannerContainer';
import Flex from '../Common/Flex';
import { Large, Title3 } from '../Common/Text';
import VerticalDivider from '../Common/VerticalDivider';
import { useAppClips } from './Context';
import IPhoneTemplate from './IPhoneTemplate';

const GoogleBanner = (): React.ReactElement => {
  const { merchantContent } = useAppClips();
  return (
    <Container>
      <BannerContainer>
        <Flex mb={4.25} justifyContent="space-between">
          <Box>
            <Box mb={{ xs: 3, md: 9.75 }} mr={2} maxWidth={{ xs: '100%', md: 360 }}>
              <Title3 mt={0} mb={1.5} color="sectionHeadingTextColor" lineHeight="32.78px">
                {merchantContent.name} is now available with Apple Pay and Google Pay™
              </Title3>
              <Large my={0} color="sectionBodyTextColor">
                Apple Pay and Google Pay™ is the easiest and most secure way to pay with {merchantContent.name}.
              </Large>
            </Box>
            <Flex alignItems="center">
              <Flex pr={2.7} mb={0.675}>
                <img src={merchantContent.data.brandLogoUrl} alt={merchantContent.name} style={{ maxWidth: 80 }} />
              </Flex>
              <VerticalDivider orientation="vertical" />
              <Flex pl={2.7}>
                <img src={ApplePayLogo} alt="Apple Pay" />
                <Box ml={2.75}>
                  <img src={GPayLogo} alt="Google Pay" />
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Hidden smDown implementation="css">
            <IPhoneTemplate width={209} height={402} cardHeight={102} cardWidth={160} />
          </Hidden>
        </Flex>
        <Flex justifyContent="center" width="100%">
          <Link href={merchantContent?.data?.googleGetStartedUrl} underline="none" target="_blank">
            <Button variant="contained" color="primary">
              Get Started
            </Button>
          </Link>
        </Flex>
      </BannerContainer>
    </Container>
  );
};

export default GoogleBanner;
