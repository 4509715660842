/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import momentTz from 'moment-timezone';
import currency from 'currency.js';
import { DEFAULT_TIMEZONE } from '../constants';

export function linkifyText(text: string): string {
  return text.replace(/(http|https):\/\/(\S+)/g, '<a href="$1://$2" target="_blank" rel="nofollow">$1://$2</a>');
}

//helper function to know current user's is not on ios environment
export function isAppleEnvironment(): boolean {
  const isMac = window.navigator.userAgent.toLowerCase().indexOf('mac');
  return isMac !== -1 ? true : false;
}

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const onChangeMobileNumber = (value: string) => {
  let val = value;
  val = val.replace(/ /gm, '');
  val = val.replace(/[^0-9]/gm, '');
  val = val.replace(/^\+?61|\|61|\D/, ''); // remove the prefix
  while (val.charAt(0) === '0') {
    val = val.replace(/0/, '');
  }
  val = val.slice(0, 9); // drop all after index 9

  let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;

  num = num.trim();
  return { mobileNumberId: val, mobileNumberDisplay: num };
};

export const onChangeEmail = (value: string) => {
  let stringVal = value.trim().toLowerCase();
  stringVal = stringVal.replace(/[^a-z|^0-9|@!#$%&'*+-/=?^_`{|}~'-]/gm, '');
  return stringVal;
};

export const padZerosToAmount = (amount = 0) => {
  if (isNaN(amount)) return '00000';
  const strAmount = Number(amount).toString();
  const amountLength = strAmount.length + 2;
  return strAmount.padEnd(amountLength, '0');
};

export const getImageBrightness = (image: HTMLImageElement) => {
  let colorSum = 0;
  try {
    // create canvas
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;

    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.drawImage(image, 0, 0);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    let r, g, b, avg;

    for (let x = 0, len = data.length; x < len; x += 4) {
      r = data[x];
      g = data[x + 1];
      b = data[x + 2];

      avg = Math.floor((r + g + b) / 3);
      colorSum += avg;
    }

    const brightness = Math.floor(colorSum / (canvas.width * canvas.height));

    if (brightness > 127.5) {
      return 'light';
    }
    return 'dark';
  } catch (error) {
    return 'light';
  }
};

export const separateFullName = (name: string) => {
  const lastSpaceIndex = name?.lastIndexOf(' ');
  let firstName = '',
    lastName = '';
  if (lastSpaceIndex) {
    firstName = name.slice(0, lastSpaceIndex);
    lastName = name.slice(lastSpaceIndex + 1);
  }
  return { firstName, lastName };
};

export const getFormattedTime = (time: string) => {
  const timeInInteger = parseInt(time ?? '0');
  if (timeInInteger === 0) {
    return '00';
  }
  if (timeInInteger) {
    const timeAppend = timeInInteger < 10 ? '0' : '';
    return `${timeAppend}${timeInInteger}`;
  }
  return time;
};

export const getInitialTime = (format: string, date?: string, selectedTimezone?: boolean) => {
  if (!selectedTimezone) {
    const freshDate = moment(new Date());
    return freshDate.format(format);
  }
  return moment(date).format(format);
};

export const getTimeZoneDisplay = (timeZone?: any): string => {
  if (!timeZone) {
    return `(UTC ${moment().format('Z')}) ${momentTz.tz.guess(true).split('/')[1]}`;
  }
  return `${timeZone.timeZoneDisplay}`;
};

export function dollarToCent(amount: number): number {
  if (amount === undefined || typeof amount !== 'number') return 0;
  return currency(amount, { precision: 4 }).multiply(100).value;
}

export const getDefaultDate = (date: Date) => {
  const coeff = 1000 * 60 * 5;
  const rounded = new Date(Math.ceil(date.getTime() / coeff) * coeff);
  return rounded;
};

export const getDateByTimezone = (date?: Date, timezone: string = DEFAULT_TIMEZONE) => {
  if (!timezone || !date) return undefined;
  const stringDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
  const dt = moment.tz(stringDate, timezone);
  return dt.toDate();
};

export const checkScheduleDateExpired = (dateString: Date | string | null | undefined) => {
  if (!dateString) {
    // If dateString is null, undefined, or an empty string, consider it expired
    return true;
  }

  const currentDate = new Date();
  const targetDate = new Date(dateString);

  // Check if the target date is in the past
  return targetDate < currentDate;
};
