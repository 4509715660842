import { Box, Container, Grid, ITheme, Link, styled } from '@material-ui/core';
import MarkNfcPay from '../../images/mark-nfc-pay.png';
import MarkApplePay from '../../images/mark-apple-pay.png';
import Flex from '../Common/Flex';
import { Large, Title1, Title2 } from '../Common/Text';
import { useAppClips } from './Context';
import IPhoneTemplate from './IPhoneTemplate';
import AppleWatchTemplate from './AppleWatchTemplate';

const CONTENT_MAX_WIDTH = 780;

const Section = styled(Box)(({ theme }: { theme: ITheme }) => ({
  width: '100%',
  background: theme.palette.sectionBackground,
  padding: theme.spacing(10),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(5),
  },
}));

const HowToUse = (): React.ReactElement => {
  const { merchantContent } = useAppClips();
  return (
    <Section textAlign="center" mb={4}>
      <Container>
        <Title1 mt={0} mb={5} color="sectionHeadingTextColor">
          How to use Apple Pay
        </Title1>
        <Large
          mt={0}
          mx="auto"
          mb={{ xs: 4, mb: 6 }}
          color="sectionBodyTextColor"
          fontSize={20}
          maxWidth={{ xs: '100%', md: CONTENT_MAX_WIDTH }}
        >
          Apple Pay is the safer, faster way to pay in stores with your iPhone and Apple Watch. Quickly check out with
          the devices you carry every day.
        </Large>
        <Box mb={6} textAlign={{ xs: 'center', md: 'left' }} mx="auto" maxWidth={CONTENT_MAX_WIDTH}>
          <Box mb={5}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Flex justifyContent={{ xs: 'center', md: 'flex-end' }}>
                  <IPhoneTemplate width={99} height={189} cardHeight={48} cardWidth={78} />
                </Flex>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Title2 mt={0} mb={1.1125} color="sectionHeadingTextColor">
                  To pay with Face ID on iPhone
                </Title2>
                <Large my={0} color="sectionBodyTextColor">
                  Double-click the side button, glance at your screen, then hold the iPhone near the reader.
                </Large>
              </Grid>
            </Grid>
          </Box>
          <Box mb={5}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Flex justifyContent={{ xs: 'center', md: 'flex-end' }}>
                  <AppleWatchTemplate width={92} height={158} cardHeight={43} cardWidth={69} />
                </Flex>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Title2 mt={0} mb={1.1125} color="sectionHeadingTextColor">
                  To pay with Apple Watch
                </Title2>
                <Large my={0} color="sectionBodyTextColor">
                  Double-click the side button and hold your Apple Watch up to the reader.
                </Large>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box maxWidth={{ xs: '100%', md: CONTENT_MAX_WIDTH }} mx="auto">
          <Large color="sectionBodyTextColor" mb={3.625}>
            Apple Pay is also the safer and faster way to pay online and in apps because you no longer have to create an
            account or fill out lengthy forms. See here for help to Set Up Apple Pay on your device.
          </Large>
          <Large color="sectionBodyTextColor" mb={3.625}>
            You can use Apple Pay wherever you see one of these symbols. To find out more go to{' '}
            <Link
              underline="none"
              color="primary"
              href={`${merchantContent.data.merchantWebsiteUrl}/applepay`}
              target="_blank"
            >
              {merchantContent.data.merchantWebsiteUrl}/applepay
            </Link>
          </Large>
        </Box>
        <Flex alignItems="center" justifyContent="center" mb={4}>
          <Flex justifyContent="flex-end" mr={1.875}>
            <img src={MarkNfcPay} alt="nfc-pay" width={121.5} height={74.77} />
          </Flex>
          <Flex justifyContent="flex-end" ml={1.875}>
            <img src={MarkApplePay} alt="apple-pay" width={121.5} height={74.77} />
          </Flex>
        </Flex>
      </Container>
    </Section>
  );
};

export default HowToUse;
