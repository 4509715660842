import { makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  img: {
    height: '8rem',
  },
}));

const hasMerchantName = (merchantName: string) => {
  return merchantName && merchantName !== 'gift';
};

export function HeaderLogo() {
  const classes = useStyles();
  const location = useLocation();
  const pathName = location?.pathname;
  const merchantName = pathName?.replace(/\//g, '');
  const showLogo = useMemo(() => {
    return !pathName?.includes('edm') && !hasMerchantName(merchantName) && pathName !== '/';
  }, [pathName, merchantName]);

  if (!showLogo) return null;

  return <img className={classes.img} src="/karta-logo-no-icon.png" alt="Karta Logo" />;
}
