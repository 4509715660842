export const SelectAmount = {
  header: 'Choose a Digital Gift Card value',
  amounts: [20, 50, 100, 200, 250, 500],
};

export const RecipientDetail = {
  header: 'Recipient’s details',
};

export const ScheduleDelivery = {
  header: 'Delivery',
  subheader: 'Send now or choose a date up to 12 months in advance',
};

export const ReviewOrder = {
  header: 'Review Order',
  subheader: 'Check the details of your order before you pay.',
};

export const Buttons = {
  update: 'Update',
  continue: 'Continue',
};

export const Payment = {
  header: 'Checkout',
  subheader: 'Complete your details and choose your payment method to complete your order.',
};

export const TermsConditions = {
  privacy: {
    text: 'Privacy Policy',
  },
  tnc: {
    text: 'Terms of Service',
  },
};
