import { Containers } from './Containers';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@mui/material';

export const useStyles = makeStyles(() => ({
  solidBarIcon: {
    height: '4px',
    width: '56px',
    marginTop: '4px',
    alignSelf: 'center',
    borderRadius: '4px',
    backgroundColor: '#E0E0E0',
  },
  mainContainer: {
    flexDirection: 'column',
  },
  errorContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alert: {
    '& .MuiAlert-message': {
      fontSize: 16,
    },
  },
}));

const getLabelColor = (theme: Theme) => {
  const { palette } = theme;

  return {
    color: palette.secondary.contrastText,
    opacity: 0.8,
  };
};

export const styles = {
  label: (theme: Theme) => ({
    ...theme.typography.h5,
    fontSize: '20px',
    lineHeight: '24px',
    marginTop: 0,
  }),
  labelContainer: {
    ...Containers.row,
    marginBottom: '24px',
  },
  labelTextContainer: {
    flex: 1,
    alignContent: 'flex-start',
  },
  labelValueContainer: {
    flex: 1,
    justifyContent: 'end',
  },
  subLabelStyle: (theme: Theme) => ({
    ...theme.typography.caption,
    fontSize: '16px',
    lineHeight: '21px',
    marginTop: '1px',
    marginBottom: '1px',
    ...getLabelColor(theme),
  }),
  subLabelValueStyle: (theme: Theme) => ({
    ...theme.typography.caption,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '21px',
    marginTop: '1px',
    marginBottom: '1px',
  }),
  copyText: (theme: Theme) => ({
    ...theme.typography.caption,
    fontSize: '15px',
    color: getLabelColor(theme).color,
  }),
  labelFooterStyle: (theme: Theme) => ({
    ...theme.typography.h4,
    fontSize: '28px',
    lineHeight: '34px',
    marginTop: '56px',
    marginBottom: 0,
    alignSelf: 'center',
  }),
  labelSubFooterStyle: (theme: Theme) => ({
    ...theme.typography.caption,
    fontSize: '17px',
    lineHeight: '22px',
    marginTop: '4px',
    marginBottom: '16px',
    alignSelf: 'center',
  }),
  dividerBarIcon: (customStyle = {}) => ({
    height: '4px',
    width: 'auto',
    marginBottom: 24,
    borderRadius: '1px',
    backgroundColor: '#F3F4FB',
    ...customStyle,
  }),
  buyButton: {
    marginTop: 0,
  },
};
