import { Box, BoxProps } from '@material-ui/core';
import AppleWatchLogo from '../../images/apple-watch.png';
import { useAppClips } from './Context';
import GiftCard from './GiftCard';

type AppleWatchTemplateProps = BoxProps & {
  height: number;
  width: number;
  cardHeight: number;
  cardWidth: number;
};

const AppleWatchTemplate = ({
  height,
  width,
  cardHeight,
  cardWidth,
  ...props
}: AppleWatchTemplateProps): React.ReactElement => {
  const { merchantContent } = useAppClips();
  return (
    <Box {...props}>
      <Box position="relative">
        <img src={AppleWatchLogo} alt="Apple Watch" width={width} height={height} />
        {merchantContent?.data?.appleIPhoneCardImageUrl && (
          <Box position="absolute" top="35%" left="11%" right="16%">
            <GiftCard height={cardHeight} width={cardWidth} borderRadius={4}>
              {merchantContent?.data?.appleIPhoneCardLogoUrl && (
                <img alt="iPhone" src={merchantContent.data.appleIPhoneCardLogoUrl} height="50%" />
              )}
            </GiftCard>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AppleWatchTemplate;
