import { useMemo } from 'react';
import { AppConfig } from '../../../constants/config';
import ButtonSheet from '../ButtonSheet';
import { useTheme } from '@mui/material/styles';
import { styles } from '../../../styles/AppClip/OrderSuccessScreenStyles';
import AppStoreBadge from '../../../images/app-store-badge.png';
import PlayStoreBadge from '../../../images/play-store-badge.png';

type FooterProps = {
  isApple: boolean;
  isLoading: boolean;
  onClickContinue: () => void;
};

export default function Footer({ onClickContinue, isApple, isLoading }: FooterProps) {
  const theme = useTheme();

  const downloadButton = useMemo(() => {
    return {
      link: isApple ? AppConfig.IOS_APP_URL : AppConfig.ANDROID_APP_URL,
      imageSource: isApple ? AppStoreBadge : PlayStoreBadge,
      imageAltText: isApple ? 'app store badge' : 'play store badge',
    };
  }, [isApple]);

  return (
    <>
      <ButtonSheet
        label="Buy another Gift Card"
        onPress={onClickContinue}
        buttonStyle={{ ...styles.buyButton, opacity: isLoading ? 0.5 : 1 }}
        disabled={isLoading}
        data-testid={'order-success-screen-buy-another-gift-card-button'}
      />

      <p style={styles.labelFooterStyle(theme)} data-testid={'keep-gifting-text'}>
        Keep gifting!
      </p>
      <p style={styles.labelSubFooterStyle(theme)} data-testid={'download-app-text'}>
        Download our app today
      </p>
      <a style={{ alignSelf: 'center' }} href={downloadButton.link} target="_blank" rel="noreferrer">
        <img
          src={downloadButton.imageSource}
          alt={downloadButton.imageAltText}
          data-testid={isApple ? 'apple-store-button' : 'play-store-button'}
          width={'148.5px'}
          style={{
            height: 'auto',
            alignSelf: 'center',
          }}
        />
      </a>
    </>
  );
}
