import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICustomiseGiftState {
  selectedAnimation: string;
}

const initialState: ICustomiseGiftState = {
  selectedAnimation: '',
};

const { actions, reducer } = createSlice({
  name: 'RecipientState',
  initialState,
  reducers: {
    setAnimation: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.selectedAnimation = payload;
    },
    resetAnimation: () => initialState,
  },
});

export const customiseGiftActions = {
  ...actions,
};

export const customiseGiftReducer = reducer;
