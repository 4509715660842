import { Button } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Box, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, ReactNode } from 'react';
import { Containers } from '../../../styles/AppClip/Containers';
import Flex from '../../Common/Flex';
import { CreditCardIcon, RadioButtonChecked, RadioButtonUnchecked } from '../Icons';

const styles = {
  labelField: (theme: Theme) => ({
    ...theme.typography.caption,
    color: '#172136',
    fontSize: '15px',
    lineHeight: '20px',
    textAlign: 'left' as const,
    marginTop: '0px',
    marginBottom: '4px',
    textTransform: 'none' as const,
  }),
  labelStyle: (theme: Theme) => ({
    ...theme.typography.h6,
    alignSelf: 'center' as const,
    color: 'text.primary',
    lineHeight: '22px',
    marginTop: '1px',
    marginBottom: '1px',
    textTransform: 'none' as const,
  }),
  paymentMethodParentContainer: (theme: Theme, isSelected: boolean) => ({
    marginBottom: '12px',
    padding: '18px 12px 18px 14px',
    backgroundColor: theme.palette.grey[100],
    border: `2px solid ${isSelected ? theme.palette.primary.dark : 'transparent'}`,
    borderRadius: '10px',
  }),
  fieldStyle: {
    backgroundColor: '#FFFFFF',
  },
  radioImage: (customStyle = {}) => ({
    ...customStyle,
    width: '34px',
    height: '24px',
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    marginRight: '16px',
  }),
};

interface PaymentMethodButton extends IRadioButton {
  selected?: boolean;
  expand?: boolean;
  show?: boolean;
  children?: ReactNode;
  onPress: () => void;
}

interface LabelFields {
  label?: string;
}

interface IRadioButton {
  imageLabel?: string;
  label?: string;
  isSelected?: boolean;
  isCreditCard?: boolean;
  customImageStyle?: CSSProperties;
  onClick?: () => void;
}

const LabelField: FC<LabelFields> = ({ label, ...props }) => {
  const theme = useTheme();

  return (
    <p style={styles.labelField(theme)} {...props}>
      {label}
    </p>
  );
};

const RadioButton: FC<IRadioButton> = ({
  imageLabel,
  label,
  isSelected,
  isCreditCard = false,
  customImageStyle,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Flex style={Containers.row} onClick={onClick}>
      <Flex
        style={{
          ...Containers.rowStart,
          alignItems: 'center',
          flex: 5,
          cursor: 'pointer',
        }}
      >
        {isCreditCard ? (
          <CreditCardIcon
            primaryColor={'#2E2E2E'}
            secondaryColor={'#FFFFFF'}
            style={styles.radioImage(customImageStyle)}
          />
        ) : (
          <img src={imageLabel} alt={label} style={styles.radioImage(customImageStyle)} />
        )}
        <Box component="p" sx={styles.labelStyle(theme)}>
          {label}
        </Box>
      </Flex>

      <Flex style={Containers.rowEnd}>
        {isSelected ? <RadioButtonChecked color={'#2E2E2E'} /> : <RadioButtonUnchecked color={'#2E2E2E'} />}
      </Flex>
    </Flex>
  );
};

const PaymentButtonMethod: FC<PaymentMethodButton> = ({
  label,
  imageLabel = '',
  selected = false,
  isCreditCard = false,
  customImageStyle,
  onPress,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Button onClick={onPress} style={styles.paymentMethodParentContainer(theme, selected)} {...props}>
      <RadioButton
        imageLabel={imageLabel}
        label={label}
        isSelected={selected}
        isCreditCard={isCreditCard}
        customImageStyle={customImageStyle}
        onClick={onPress}
      />
    </Button>
  );
};

export { LabelField, PaymentButtonMethod, RadioButton };
