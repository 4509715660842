import { FC } from 'react';
import Flex from '../Common/Flex';

interface ISpacer {
  height?: string;
}

const Spacer: FC<ISpacer> = ({ height }) => {
  return (
    <Flex
      style={{
        height: height,
        width: '100%',
      }}
    />
  );
};

export default Spacer;
