import { Box, ITheme, styled } from '@material-ui/core';

const BannerContainer = styled(Box)(({ theme }: { theme: ITheme }) => ({
  width: '100%',
  maxWidth: 1184,
  backgroundColor: theme.palette.sectionBackground,
  margin: `0 auto ${theme.spacing(15)}px`,
  borderRadius: theme.spacing(3),
  padding: `${theme.spacing(7.5)}px ${theme.spacing(27)}px`,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    margin: `0 auto ${theme.spacing(5)}px`,
  },
}));

export default BannerContainer;
