export const PAYMENT_ERRORS = {
  cardNumberInvalid: 'Please enter a valid Visa, Mastercard or Amex card number.',
  cardExpiryInvalid: 'Please enter a valid expiry date.',
  cardCvcInvalid: 'Please enter a valid security code.',
};

export const ERROR_MESSAGE_ORDER_RECIEPT =
  'Dear Customer, please find we have accepted your payment though was unable to create a Gift Card at this time, please contact customer support at';

export const GENERAL_ERRORS = {
  ErrorOnSubmit: {
    id: 'ErrorOnSubmit',
    label: 'Connectivity Error',
    message: 'Sorry, there’s an issue with network connectivity. Please check your network connection and try again.',
    buttonLabel: 'OK',
  },
  ErrorOnPageLoad: {
    id: 'ErrorOnPageLoad',
    label: 'Connectivity Error',
    message: 'Sorry, there’s an issue with network connectivity. Please check your network connection and try again.',
    buttonLabel: 'Try Again',
  },
  ErrorSystemOnSubmit: {
    id: 'ErrorSystemOnSubmit',
    label: 'Connectivity Error',
    message: 'An unexpected system error has occurred. We apologise for the inconvenience.',
    buttonLabel: 'Start Over',
  },
  ErrorNotFound: {
    id: 'ErrorNotFound',
    label: 'Transaction not exist!',
    message: 'An unexpected system error has occurred. We apologise for the inconvenience.',
    buttonLabel: 'Start Over',
  },
  ErrorCardCreationFailed: {
    id: 'ErrorCardCreationFailed',
    label: 'Card Creation Failed!',
    message: 'The order has been created, but there was an unexpected system error while trying to create the card.',
    buttonLabel: 'Start Over',
  },
};

export const RISK_KYC_RULE = 'RISK_KYC_RULE';
export const RISK_FRAUD_RULE = 'RISK_FRAUD_RULE';

export const RISK_API_ERRORS = {
  ErrorOnRiskBlocked: {
    id: 'ErrorOnRiskBlocked',
    label: 'Sorry!',
    message: 'We’re sorry for the inconvenience, we are unable to process your order.',
    buttonLabel: '',
  },
  ErrorOnKycRequired: {
    id: 'ErrorOnKycRequired',
    label: 'We require more information!',
    message:
      "We’re sorry for the inconvenience, we are reviewing your order and will need additional time to validate your request. You'll receive a follow-up email from our customer support team shortly.\n\n ",
    buttonLabel: '',
  },
};
