/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, createContext, PropsWithChildren } from 'react';
import { BoxProps, Theme } from '@material-ui/core';
import AppTheme from '../../theme/options';
import { MerchantContent } from './types';
import { useLocation } from 'react-router-dom';
import { MERCHANT_CONTENTS } from './constants';

interface AppClipsContextProviderProps {
  merchantContent: MerchantContent;
  getThemeForMerchant: () => Theme;
}

export const AppClipsContext = createContext<AppClipsContextProviderProps | null>(null);

const useUrlQuery = () => new URLSearchParams(useLocation().search);

export const AppClipsContextProvider = ({ children }: PropsWithChildren<BoxProps>): React.ReactElement | null => {
  const query = useUrlQuery();
  const [merchantContent, setMerchantContent] = useState<MerchantContent>();

  const getThemeForMerchant = () => {
    switch (merchantContent?.name) {
      default:
        return AppTheme.default;
    }
  };

  useEffect(() => {
    const merchant = query.get('merchant');
    const defaultContent = MERCHANT_CONTENTS.find((c) => c.name === 'Karta');
    if (!defaultContent) return;
    if (!merchant) {
      setMerchantContent(defaultContent);
      return;
    }
    const merchantData = MERCHANT_CONTENTS.find((c) => c.name === merchant);
    if (merchantData) {
      setMerchantContent({
        name: merchantData.name,
        data: {
          ...defaultContent.data,
          ...merchantData.data,
        },
      });
    }
  }, []);

  if (!merchantContent) return null;

  return (
    <AppClipsContext.Provider value={{ merchantContent, getThemeForMerchant }}>
      {!!merchantContent && children}
    </AppClipsContext.Provider>
  );
};

export const useAppClips = (): AppClipsContextProviderProps => {
  return useContext(AppClipsContext) as AppClipsContextProviderProps;
};
