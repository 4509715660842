export const Containers = {
  row: {
    flex: 1,
    flexDirection: 'row' as const,
  },
  column: {
    flex: 1,
    flexDirection: 'column' as const,
    width: '100%',
  },
  rowCenterCenter: {
    flex: 1,
    flexDirection: 'row' as const,
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowStart: {
    flex: 1,
    flexDirection: 'row' as const,
    alignItems: 'center' as const,
    justifyContent: 'flex-start' as const,
  },
  rowEnd: {
    flex: 1,
    flexDirection: 'row' as const,
    alignItems: 'center' as const,
    justifyContent: 'flex-end' as const,
  },
};
