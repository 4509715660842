import '@fontsource/roboto';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { HeaderLogo } from './components/Common/HeaderLogo';
import Apple from './components/Edm/ApplePage';
import Google from './components/Edm/GooglePage';
import Form from './components/Form';
import { PAYMENT_REDIRECT_PATH } from './constants';
import GaEventsContextProvider from './contexts/GaEventsContext';
import Home from './screens/AppClipWeb/Home';
import PaymentRedirect from './screens/PaymentRedirect';
import { AppClipThemes } from './theme';

const useStyles = makeStyles(() => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const App = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={AppClipThemes.karta}>
        <GaEventsContextProvider>
          <Router>
            <div className={classes.app}>
              <HeaderLogo />
              <Routes>
                <Route path="/edm/apple" element={<Apple />} />
                <Route path="/edm/google" element={<Google />} />
                <Route path="/gift" element={<Form />} />
                <Route path={PAYMENT_REDIRECT_PATH} element={<PaymentRedirect />} />
                <Route path="/" element={<Home />}>
                  <Route path=":merchantName" element={<Home />} />
                </Route>
              </Routes>
            </div>
          </Router>
        </GaEventsContextProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
