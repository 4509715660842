/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */

export const conversion = (sendTo: string) => {
  try {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: sendTo,
      });
    }
  } catch (error) {
    console.error('ga.ts | conversion', error);
  }
};
