import { AppConfig, FETCH_ERROR } from '../../constants';
import { CardPurchaseTransaction, FeesGstResponse } from '../../types';

const { API } = AppConfig;
const { CARD_BASE_URL, STRIPE_PURCHASE } = API;

type PurchaseTransactionResponse = 'not_found' | 'success' | 'failure';

type CardPurchaseTransactionResponse = {
  status: PurchaseTransactionResponse;
  data?: CardPurchaseTransaction;
};

export const getPurchaseTransaction = async (
  id?: string | null,
  appId?: string,
): Promise<CardPurchaseTransactionResponse | undefined> => {
  if (!id) return undefined;
  const cardUrl = CARD_BASE_URL?.endsWith('/') ? CARD_BASE_URL.substring(0, CARD_BASE_URL.length - 1) : CARD_BASE_URL;
  const purchaseUrl = STRIPE_PURCHASE?.startsWith('/')
    ? STRIPE_PURCHASE.substring(1, STRIPE_PURCHASE.length)
    : STRIPE_PURCHASE;
  const url = `${cardUrl}/${purchaseUrl}/${id}?appId=${appId}`;
  const response = await fetch(url, { method: 'GET' });
  if (response.ok) {
    const data = await response.json();
    return { status: 'success', data };
  }
  if (response.status === 404) return { status: 'not_found' };
  throw new Error(FETCH_ERROR);
};

export const getFeesAndGst = async (partner: string, program: string, appId: string): Promise<FeesGstResponse> => {
  try {
    const url = `${CARD_BASE_URL}/fees?partner=${partner}&program=${program}&appId=${appId}`;
    const response = await fetch(url, { method: 'GET' });

    if (!response.ok) {
      throw new Error('Failed to fetch fees');
    }

    const data = await response.json();
    return data as FeesGstResponse;
  } catch (error) {
    console.error('Error fetching fees:', error);
    throw error;
  }
};
