import { Box, Container } from '@material-ui/core';
import ApplePayLogo from '../../images/apple-pay-logo.svg';
import BannerContainer from '../Common/BannerContainer';
import Flex from '../Common/Flex';
import { Title1 } from '../Common/Text';
import VerticalDivider from '../Common/VerticalDivider';
import { useAppClips } from './Context';
import IPhoneTemplate from './IPhoneTemplate';

const AppleBanner = (): React.ReactElement => {
  const { merchantContent } = useAppClips();
  return (
    <Container>
      <BannerContainer>
        <Flex flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between">
          <Box mb={{ xs: 3, md: 0 }}>
            <Box mb={{ xs: 3, md: 15.125 }} mr={2} maxWidth={{ xs: '100%', md: 360 }}>
              <Title1 color="sectionHeadingTextColor" lineHeight="50px">
                Pay safer and faster using {merchantContent.name} with Apple Pay.
              </Title1>
            </Box>
            <Flex alignItems="center" justifyContent={{ xs: 'center', md: 'flex-start' }}>
              <Flex alignItems="center" pr={2.7} mb={0.675}>
                <img src={merchantContent.data.brandLogoUrl} alt={merchantContent.name} style={{ maxHeight: 21 }} />
              </Flex>
              <VerticalDivider orientation="vertical" />
              <Flex alignItems="center" pl={2.7}>
                <img src={ApplePayLogo} alt="Apple Pay" />
              </Flex>
            </Flex>
          </Box>
          <IPhoneTemplate
            width={209}
            height={402}
            cardHeight={102}
            cardWidth={160}
            display="flex"
            justifyContent={{ xs: 'center', md: 'flex-start' }}
          />
        </Flex>
      </BannerContainer>
    </Container>
  );
};

export default AppleBanner;
