import { FC } from 'react';

import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Flex from '../Common/Flex';
import { ButtonSheet } from '.';
import { Containers } from '../../styles/AppClip';
interface IErrorComponent {
  label?: string;
  message?: string;
  buttonLabel?: string;
  onPress: () => void;
}

const styles = {
  label: (theme: Theme) => ({
    fontSize: 17,
    lineHeight: '22px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    letterSpacing: 'normal',
    textAlign: 'center' as const,
  }),
  message: (theme: Theme) => ({
    fontSize: 16,
    letterSpacing: 'normal',
    lineHeight: '22px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    textAlign: 'center' as const,
    marginTop: '2px',
    marginBottom: '3px',
  }),
};

export const ErrorComponent: FC<IErrorComponent> = ({ label = '', message = '', buttonLabel = '', onPress }) => {
  const theme = useTheme();

  return (
    <Flex
      style={{
        ...Containers.column,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <span style={styles.label(theme)}>{label}</span>
      <span style={styles.message(theme)}>{message}</span>
      <Flex>
        <ButtonSheet buttonStyle={{ marginTop: 10, marginBottom: '3px' }} label={buttonLabel} onPress={onPress} />
      </Flex>
    </Flex>
  );
};
