import { Box, BoxProps, Grid, styled, Container } from '@material-ui/core';
import { Body, Title1, Title3 } from '../Common/Text';
import { useAppClips } from './Context';
import { MerchantContent } from './types';

export const YOUTUBE_LINK_REGEX =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

const FaqSection = styled(Box)(() => ({
  width: '100%',
  maxWidth: 960,
  margin: '0 auto',
}));

const getFaqItems = (data: MerchantContent) => {
  return [
    {
      title: 'How to use Apple Pay',
      description:
        'You can use Apple Pay to make payments at any merchant where contactless payments are accepted. Simply look for the contactless symbol or Apple Pay mark. You may also use Apple Pay in-app and on the web with Safari when you see the “Buy with Apple Pay” button or Apple Pay as a payment option.',
    },
    {
      title: 'What is my Device Account Number, and where can I find it?',
      description:
        'All cards stored in Apple Pay are allocated a Device Account Number. This unique number ensures your payment information isn’t stored on your device or shared with the merchant when making a payment. You can find your Device Account Number by opening the Wallet app, selecting your card and tapping the (i) symbol in the bottom righter corner.',
    },
    {
      title: 'Which cards are eligible for Apple Pay?',
      description: `Apple Pay can be used with all ${data.data.merchantGiftCardsText}.`,
    },
    {
      title: 'Where can I find out more information about Apple Pay?',
      description:
        'To find out more information on how to make secure payments and set up Apple Pay with your Card, please visit the Apple Pay webpage',
    },
    {
      title: 'What Apple devices are compatible with Apple Pay?',
      description:
        'Apple Pay works with iPhone 6 and later in stores, apps, and websites in Safari; with Apple Watch in stores and apps (requires iPhone 6 or later); For a list of compatible Apple Pay devices, see here.',
    },
  ];
};

const getFaqVideos = () => {
  return [
    {
      title: 'How to use Face ID with Apple Pay',
      url: 'https://youtube.com/embed/4X4Vd130sbE',
    },
    {
      title: 'How to use Touch ID with Apple Pay',
      url: 'https://youtube.com/embed/7QszoO0bRRk',
    },
  ];
};

const Faqs = (props: BoxProps): React.ReactElement => {
  const { merchantContent } = useAppClips();
  const faqItems = getFaqItems(merchantContent);
  const faqVideos = getFaqVideos();
  return (
    <Container>
      <FaqSection {...props} py={{ xs: 4, md: 10 }}>
        <Box mb={{ xs: 4, md: 8 }}>
          <Grid container spacing={2}>
            {faqVideos.map((v, i) => (
              <Grid item xs={12} sm={6} key={i}>
                <Title3 mt={0} mb={2} bold color="headingTextColor" fontSize={20}>
                  {v.title}
                </Title3>
                <Box>
                  {v.url.match(YOUTUBE_LINK_REGEX) ? (
                    <iframe
                      title={v.title}
                      width="100%"
                      src={v.url}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      frameBorder={0}
                      style={{ minHeight: 259 }}
                    />
                  ) : (
                    <video width="100%" src={v.url} controls style={{ minHeight: 259 }} />
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Title1 mt={0} mb={5} color="headingTextColor" textAlign="center">
          Apple Pay FAQs
        </Title1>
        {faqItems.map((i, index) => (
          <Box mb={2} key={index}>
            <Title3 mt={0} mb={2} bold color="headingTextColor" fontSize={20}>
              {i.title}
            </Title3>
            <Body mt={0} mb={2} color="bodyTextColor">
              {i.description}
            </Body>
          </Box>
        ))}
      </FaqSection>
    </Container>
  );
};
export default Faqs;
