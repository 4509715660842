import DateFnsUtils from '@date-io/date-fns';
import { Box, BoxProps, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import Flex from '../Common/Flex';
import { TextFieldWithLabel } from './FormComponents';
import { getDefaultDate } from '../../utils/helpers';
import calendarSvg from '../../images/calendar-date.svg';
import moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from '../../constants';

type Props = BoxProps & {
  label: string;
  value?: Date | null;
  isOpen: boolean;
  changeOpen: (isOpen: boolean) => void;
  onChange: (date: Date | null) => void;
  min?: Date | null;
  max?: Date | null;
  error?: string;
};

const useStyles = makeStyles({
  picker: {
    '& .MuiPickersStaticWrapper-staticWrapperRoot': {
      fontFamily: 'Rubik, Roboto, "Open Sans", sans-serif',
      width: '100%',
      boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
      borderRadius: 8,
    },
    '& .MuiPickersBasePicker-pickerView': {
      width: '100%',
      maxWidth: '100%',

      '& .MuiPickersDay-daySelected': {
        backgroundColor: '#2E2E2E',
        color: 'white',
        borderRadius: 8,
      },
      '& .MuiPickersCalendarHeader-switchHeader': {
        '& .MuiPickersCalendarHeader-transitionContainer': {
          '& p': {
            fontWeight: 'bold',
            fontFamily: 'Rubik, Roboto, "Open Sans", sans-serif',
          },
        },
      },
    },
    '& .MuiPickersCalendar-transitionContainer': {
      minHeight: 248,
    },
    '& .MuiPickersCalendarHeader-daysHeader': {
      '& .MuiPickersCalendarHeader-dayLabel': {
        width: 48,
        fontWeight: 'bold',
        color: '#2E2E2E',
        textTransform: 'uppercase',
        fontFamily: 'Rubik, Roboto, "Open Sans", sans-serif',
      },
    },
    '& .MuiPickersDay-day': {
      height: 48,
      width: 48,
      borderRadius: 8,
      '&.MuiPickersDay-current': {
        color: '#2E2E2E',
        border: '1px solid #CECECE',
      },
    },
  },
  text: {
    '& input': {
      width: '100%',
    },
    '& .text-field': {
      borderRadius: 8,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: 'transparent',
      '&:hover > div, &:focus > div': {
        borderColor: '#2E2E2E',
      },
    },
  },
});

const DatePicker = ({ value, isOpen, label, min, max, changeOpen, onChange, error, ...rest }: Props) => {
  const styles = useStyles();
  const [isDirty, setIsDirty] = useState(false);
  const [open, setOpen] = useState(isOpen);
  const [dateDisplay, setDateDisplay] = useState<string | undefined>(!value ? undefined : format(value, 'd MMM yyyy'));
  const [date, setDate] = useState<Date | null | undefined>(value);

  const handleDateClicked = () => {
    setOpen(true);
    changeOpen(true);
  };

  const handleDateChanged = (d: Date) => {
    let newDate = d;
    if (!date) {
      // for the first time, add 1 minute more
      // current time is 06:20am, it will set to 06:20am, it's not the future time
      d.setMinutes(d.getMinutes() + 1);
      // currently the time is local time, convert to AEST time
      newDate = new Date(moment.tz(d, DEFAULT_TIMEZONE).format('YYYY-MM-DDTHH:mm:ss'));
    }
    onChange(getDefaultDate(newDate));
    setDateDisplay(format(newDate, 'd MMM yyyy'));
    setIsDirty(true);
  };

  useEffect(() => {
    setDate(value);
    setDateDisplay(!value ? '' : format(value ?? new Date(), 'd MMM yyyy'));
  }, [value]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Box position="relative" width="100%" {...rest}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Flex className={styles.picker} flexDirection="column" alignItems="center">
          <Box className={styles.text} position="relative" width="100%" onClick={handleDateClicked}>
            <TextFieldWithLabel
              containerStyle={{ width: '100%' }}
              id={label.toLowerCase().split(' ').join('-')}
              label={label}
              value={dateDisplay}
              readOnly
              placeholder="Select date"
              icon={calendarSvg}
              errorMessage={isDirty ? error : undefined}
            />
          </Box>
          {open && (
            <KeyboardDatePicker
              views={['year', 'month', 'date']}
              fullWidth
              variant="static"
              disableToolbar
              margin="normal"
              value={date}
              minDate={min}
              maxDate={max}
              onChange={(d) => handleDateChanged(d as Date)}
            />
          )}
        </Flex>
      </MuiPickersUtilsProvider>
    </Box>
  );
};

export default DatePicker;
