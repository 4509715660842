import { DisplaySm, PrimaryButton, SecondaryButton, TertiaryButton, TextMd } from '@545490/karta-web-components';
import { styled } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Dialog, DialogProps, Grid } from '@mui/material';
import { styles } from '../constants/styles';
import { useIsMobileView } from '../hooks';

type Props = DialogProps & {
  handleClose: () => void;
  handleConfirm: () => void;
};

const ButtonStyle = {
  fontWeight: 'bold',
  width: '100%',
  textTransform: 'none',
  marginTop: 16,
};

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-container': {
    '& .MuiDialog-paper': {
      borderRadius: 16,
      maxWidth: 500,
    },
  },
}));

const LeaveDialog = (props: Props) => {
  const isMobile = useIsMobileView();
  const { handleClose, handleConfirm, ...rest } = props;

  return (
    <StyledDialog data-testid="leave-modal" fullWidth aria-labelledby="leave-dialog" {...rest}>
      <Box
        sx={{
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative', // Add position relative to enable absolute positioning for the close button
        }}
      >
        {/* Close button positioned absolute */}
        <Box sx={{ position: 'absolute', top: 0, right: 0, margin: 1 }}>
          <CloseIcon onClick={() => handleClose()} />
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
          <DisplaySm
            sx={{
              fontFamily: 'Rubik',
              fontSize: 20,
              fontWeight: 700,
              my: 0,
              flex: 1,
            }}
          >
            Are you sure you want to leave?
          </DisplaySm>
        </Box>
        <TextMd
          sx={{
            fontFamily: 'Rubik',
          }}
          mt={1}
        >
          You're moments away for completing your purchase!
        </TextMd>
        <Grid container spacing={2} mt={{ xs: 0, md: 2 }}>
          <Grid item xs={12} md={6}>
            <SecondaryButton
              fullWidth
              variant="outlined"
              style={styles.button({
                ...ButtonStyle,
                marginTop: 0,
                background: '#ffffff',
                borderColor: '#2E2E2E',
                color: '#2E2E2E',
              })}
              onClick={handleConfirm}
            >
              Leave
            </SecondaryButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <PrimaryButton
              fullWidth
              variant="contained"
              style={styles.button({ ...ButtonStyle, marginTop: 0 })}
              onClick={handleClose}
              disableElevation
            >
              Continue my purchase
            </PrimaryButton>
          </Grid>
        </Grid>
      </Box>
    </StyledDialog>
  );
};

export default LeaveDialog;
