import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import {
  SCREEN_IDS,
  borderRadiusHeaderLogo,
  borderRadiusInnerPhoneCorners,
  getMerchantLogoTopPosition,
  setHeaderHeightImage,
  setHeaderHeightLogoImage,
  setHeaderPositionLogo,
  setHeaderWidthLogoImage,
} from '../../constants';
import { useIsLargerScreen } from '../../hooks/useIsMobileView';
import { RootState, useAppSelector } from '../../store';
import Flex from '../Common/Flex';

export const HeaderImage = ({ backgroundImage = '', backgroundColor = '', isError = false, ...props }) => {
  const isDesktop = useIsLargerScreen();
  const { screenStates } = useAppSelector((state: RootState) => state.screen);
  const borderRadius = useMemo(() => {
    return isDesktop ? borderRadiusInnerPhoneCorners : 0;
  }, [isDesktop]);

  const hideImage = useMemo(() => {
    return screenStates.screen === SCREEN_IDS.orderSuccessScreen;
  }, [screenStates.screen, isDesktop]);

  if (isDesktop) return null;

  return (
    <Box position="relative">
      <Flex style={styles.container(isDesktop)}>
        {hideImage ? (
          <Flex style={{ height: setHeaderHeightImage(isDesktop) }} />
        ) : (
          <>
            {!isError && backgroundImage ? (
              <img
                {...props}
                loading="eager"
                src={backgroundImage}
                alt={'merchantBackgroudURL'}
                data-testid={'mobile-header-image'}
                style={{
                  height: setHeaderHeightImage(isDesktop),
                  width: '100%',
                  objectFit: 'cover',
                  borderTopLeftRadius: borderRadius,
                  borderTopRightRadius: borderRadius,
                }}
              />
            ) : (
              <DefaultMerchantBackground backgroundColor={backgroundColor} />
            )}
          </>
        )}
      </Flex>
    </Box>
  );
};

export const HeaderBackGroundImage = ({
  merchantLogo = '',
  merchantName = '',
  screen = '',
  loading = false,
  selected = false,
  isError = false,
  isDark = false,
  ...props
}) => {
  const theme = useTheme();
  const isDesktop = useIsLargerScreen();
  const { screenStates } = useAppSelector((state: RootState) => state.screen);
  const { tenantDetails } = useAppSelector((state: RootState) => state.tenant);

  const renderMerchantImage = () =>
    !isError ? (
      <img
        loading="eager"
        src={merchantLogo}
        alt={'merchantLogoURL'}
        style={{
          maxHeight: setHeaderHeightLogoImage(screen, isDesktop),
          width: setHeaderWidthLogoImage(screen, isDesktop),
          objectFit: 'contain',
          borderRadius: borderRadiusHeaderLogo(screen),
        }}
        {...props}
      />
    ) : (
      <DefaultMerchantLogo merchantName={merchantName} isDark={isDark} />
    );

  const hideImage = useMemo(() => {
    return screenStates.screen !== SCREEN_IDS.orderSuccessScreen;
  }, [screenStates.screen]);

  if (isDesktop) return null;

  return (
    <Flex style={styles.headerBackgroundContainer(screen, loading, isDesktop, selected)}>
      {loading ? (
        <Flex style={{ height: setHeaderHeightLogoImage(screen, isDesktop) }}>
          <CircularProgress
            style={{
              alignSelf: 'center',
              color: theme.palette.primary.dark,
            }}
          />
        </Flex>
      ) : (
        <>
          {!tenantDetails.configuration.appClip?.hideLogo || !hideImage ? (
            renderMerchantImage()
          ) : (
            <Flex style={{ height: setHeaderHeightLogoImage(screen, isDesktop) }} />
          )}
        </>
      )}
    </Flex>
  );
};

export const DefaultMerchantBackground = ({ backgroundColor = '' }) => {
  const theme = useTheme();
  const isDesktop = useIsLargerScreen();
  const borderRadius = useMemo(() => {
    return isDesktop ? borderRadiusInnerPhoneCorners : 0;
  }, [isDesktop]);

  if (isDesktop) return null;

  return (
    <Flex
      style={{
        backgroundColor: backgroundColor || theme.palette.primary.main,
        height: setHeaderHeightImage(isDesktop),
        width: '100%',
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
      }}
    />
  );
};

export const DefaultMerchantLogo = ({ merchantName = '', isDark = false }) => {
  const theme = useTheme();
  const isDesktop = useIsLargerScreen();
  const { palette, typography } = theme;
  const { light, dark } = palette.primary;

  if (isDesktop) return null;

  return (
    <p
      style={{
        fontFamily: typography.fontFamily,
        fontSize: '30px',
        fontWeight: 600,
        textAlign: 'center',
        color: isDark ? light : dark,
        textDecoration: 'none',
        marginRight: 16,
        marginLeft: 16,
        flexWrap: 'wrap',
      }}
    >
      {merchantName}
    </p>
  );
};

const styles = {
  headerBackgroundContainer: (screen: string, loading: boolean, isDesktop: boolean, selected: boolean) => ({
    marginTop: setHeaderPositionLogo(screen, loading, isDesktop, selected),
    top: getMerchantLogoTopPosition(screen, isDesktop),
    position: 'relative' as const,
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
  }),
  container: (isDesktop: boolean) => ({
    ...(isDesktop && { borderTopLeftRadius: borderRadiusInnerPhoneCorners }),
    ...(isDesktop && { borderTopRightRadius: borderRadiusInnerPhoneCorners }),
    zIndex: -1,
  }),
};
