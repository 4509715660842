import { SCREEN_IDS } from './ids';

export const borderRadiusSheetTopCorners = '22px';
export const borderRadiusInnerPhoneCorners = '22px';

const { selectAmountScreen, recipientsDetailScreen, reviewOrderScreen, paymentCardScreen, orderSuccessScreen } =
  SCREEN_IDS;

const headerLogoHeight = {
  selectAmountScreen: {
    normal: 128,
    small: 85,
  },
  OtherScreen: {
    normal: 50,
    small: 50,
  },
  OrderSuccessScreen: {
    normal: 204.84,
    small: 204.84,
  },
};

const headerLogoWidth = {
  selectAmountScreen: {
    normal: 294.0,
    small: 294.0,
  },
  OtherScreen: {
    normal: 150,
    small: 130,
  },
  OrderSuccessScreen: {
    normal: 324,
    small: 324,
  },
};

const headerPositionLogo = {
  selectAmountScreen: {
    normal: -300,
    small: -170,
  },
  OtherScreen: {
    normal: -206,
    small: -205,
  },
  ReviewOrderScreen: {
    normal: -538,
    small: -205,
  },
  PaymentCardScreen: {
    normal: -290,
    normalSelected: -176,
    small: -220,
  },
  OrderSuccessScreen: {
    normal: -212,
    small: -212,
    loadingNormal: -213,
    loadingSmall: -212,
  },
};

const positionBottomSheet = {
  selectAmountScreen: {
    normal: 150,
    small: 56,
  },
  RecipientsDetailScreen: {
    normal: 18,
    small: 25,
  },
  ReviewOrderScreen: {
    normal: 18,
    small: 25,
  },
  PaymentCardScreen: {
    normal: 30,
    small: 25,
  },
  OrderSuccessScreen: {
    normal: -150,
    small: 0,
    loadingNormal: 10,
    loadingSmall: 9,
  },
};

const logoTopPosition = {
  selectAmount: {
    large: 90,
    small: -30,
  },
  otherScreen: {
    large: -10,
    small: -20,
  },
};

const MarginSelectedAmountBig = {
  normal: 56,
  small: 56,
};

export function setHeaderHeightImage(isDesktop: boolean): GLfloat {
  return isDesktop ? 276 : 276;
}

export function setHeaderHeightLogoImage(screen: string, isScreenLarge: boolean): GLfloat {
  switch (screen) {
    case selectAmountScreen:
      return isScreenLarge ? headerLogoHeight.selectAmountScreen.normal : headerLogoHeight.selectAmountScreen.small;
    case recipientsDetailScreen:
    case reviewOrderScreen:
    case paymentCardScreen:
    case orderSuccessScreen:
      return isScreenLarge ? headerLogoHeight.OrderSuccessScreen.normal : headerLogoHeight.OrderSuccessScreen.small;
    default:
      return isScreenLarge ? headerLogoHeight.selectAmountScreen.normal : headerLogoHeight.selectAmountScreen.small;
  }
}

export function setHeaderWidthLogoImage(screen: string, isScreenLarge: boolean): GLfloat {
  switch (screen) {
    case selectAmountScreen:
      return isScreenLarge ? headerLogoWidth.selectAmountScreen.normal : headerLogoWidth.selectAmountScreen.small;
    case recipientsDetailScreen:
    case reviewOrderScreen:
    case paymentCardScreen:
    case orderSuccessScreen:
      return isScreenLarge ? headerLogoWidth.OrderSuccessScreen.normal : headerLogoWidth.OrderSuccessScreen.small;
    default:
      return isScreenLarge ? headerLogoWidth.selectAmountScreen.normal : headerLogoWidth.selectAmountScreen.small;
  }
}

export function setHeaderPositionLogo(
  screen: string,
  loading: boolean,
  isScreenLarge: boolean,
  selected: boolean,
): GLfloat {
  const { loadingNormal, normal, loadingSmall, small } = headerPositionLogo.OrderSuccessScreen;
  switch (screen) {
    case selectAmountScreen:
      return isScreenLarge ? headerPositionLogo.selectAmountScreen.normal : headerPositionLogo.selectAmountScreen.small;
    case recipientsDetailScreen:
    case reviewOrderScreen:
    case paymentCardScreen:
    case orderSuccessScreen:
      return isScreenLarge ? (loading ? loadingNormal : normal) : loading ? loadingSmall : small;
    default:
      return isScreenLarge ? headerPositionLogo.selectAmountScreen.normal : headerPositionLogo.selectAmountScreen.small;
  }
}

export function setBottomSheetPosition(screen: string, loading: boolean, isScreenLarge: boolean): GLfloat {
  const { loadingNormal, normal, loadingSmall, small } = positionBottomSheet.OrderSuccessScreen;
  switch (screen) {
    case selectAmountScreen:
      return isScreenLarge
        ? positionBottomSheet.selectAmountScreen.normal
        : positionBottomSheet.selectAmountScreen.small;
    case recipientsDetailScreen:
    case reviewOrderScreen:
    case paymentCardScreen:
    case orderSuccessScreen:
      return isScreenLarge ? (loading ? loadingNormal : normal) : loading ? loadingSmall : small;
    default:
      return isScreenLarge
        ? positionBottomSheet.selectAmountScreen.normal
        : positionBottomSheet.selectAmountScreen.small;
  }
}

export function setMarginSelectedAmountBig(isScreenLarge: boolean): GLfloat {
  return isScreenLarge ? MarginSelectedAmountBig.normal : MarginSelectedAmountBig.small;
}

export function borderRadiusHeaderLogo(screen: string): GLfloat {
  return orderSuccessScreen === screen ? 10 : 0;
}

export const getMerchantLogoTopPosition = (screen: string, isDesktop: boolean) => {
  const { selectAmount, otherScreen } = logoTopPosition;
  const topPosition = screen === selectAmountScreen ? selectAmount : otherScreen;
  return isDesktop ? topPosition.large : topPosition.small;
};
