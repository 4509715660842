import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { useTheme } from '@mui/material/styles';
import { AppConfig } from '../../constants';
import { useIsLargerScreen } from '../../hooks';
import Flex from './Flex';

const MarketingBanner = ({ style }: { style?: CSSProperties }) => {
  const theme = useTheme();
  const isDesktop = useIsLargerScreen();

  const showMarketingBanner = AppConfig.DISPLAY_MARKETING_BANNER?.toLowerCase() === 'true';

  if (!showMarketingBanner) return null;

  return (
    <Flex
      sx={{
        width: '100%',
        height: isDesktop ? 34 : 44,
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: '#F7FAFF',
        ...(style ?? {}),
      }}
    >
      <a
        style={{
          ...theme.typography.h6,
          color: '#000',
          fontSize: 12,
          textDecoration: 'underline',
          textAlign: 'center',
          width: isDesktop ? 400 : 200,
        }}
        href={AppConfig.DISPLAY_MARKETING_BANNER_LINK}
        target={'_blank'}
        rel="noreferrer"
      >
        {AppConfig.DISPLAY_MARKETING_BANNER_TEXT}
      </a>
    </Flex>
  );
};

export default MarketingBanner;
