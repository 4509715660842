import { Theme } from '@mui/material';
import { Typography } from '../styles/AppClip';

export const styles = {
  button: (customStyle = {}) => ({
    backgroundColor: '#2E2E2E',
    color: '#FFF',
    borderRadius: 10,
    flex: 1,
    marginTop: 36,
    paddingTop: 14,
    paddingBottom: 13,
    opacity: 1, // overide the default behavior when disabled
    ...customStyle,
  }),
  outlinedButton: (customStyle = {}) => ({
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: '#2E2E2E',
    borderRadius: 10,
    flex: 1,
    marginTop: 36,
    paddingTop: 14,
    fontWeight: 'bold',
    paddingBottom: 13,
    opacity: 1, // overide the default behavior when disabled
    ...customStyle,
  }),
  backButton: (customStyle = {}) => ({
    backgroundColor: '#2E2E2E',
    borderRadius: 10,
    marginTop: 36,
    marginRight: 16,
    paddingTop: 14,
    paddingBottom: 13,
    paddingLeft: 15,
    paddingRight: 15,
    opacity: 1, // overide the default behavior when disabled
    ...customStyle,
  }),
  buttonText: (theme: Theme) => ({
    ...Typography.rubik.semiBold,
    color: theme.palette.common.white,
    lineHeight: '21px',
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'center',
    textTransform: 'capitalize',
  }),
};
