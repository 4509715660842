const typography = {
  fontFamily: 'Manrope',
  display: {
    fontSize: 64,
    fontWeight: 'fontWeightBold',
    lineHeight: '68px',
    letterSpacing: '-0.022em',
  },
  title1: {
    fontSize: 45,
    fontWeight: 'fontWeightBold',
    lineHeight: '50px',
    letterSpacing: '-0.022em',
  },
  title2: {
    fontSize: 38,
    fontWeight: 'fontWeightBold',
    lineHeight: '51.91px',
    letterSpacing: '-0.021em',
  },
  title3: {
    fontSize: 24,
    fontWeight: 'fontWeightBold',
    lineHeight: '28px',
    letterSpacing: '-0.019em',
  },
  large: {
    fontSize: 18,
    lineHeight: '24.59px',
    letterSpacing: '-0.014em',
  },
  body: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '-0.011em',
  },
  small: {
    fontSize: 12,
    lineHeight: '16px',
  },
};

export default typography;
