import { MerchantContent, SocialsType } from './types';

export const KARTA_CONTENT: MerchantContent = {
  name: 'Karta',
  data: {
    brandLogoUrl: 'https://i.ibb.co/zxTygrD/logoBig.png',
    merchantWebsiteUrl: 'https://karta.com.au',
    merchantGiftCardsText: 'Karta Gift Cards',
    termsAndConditionsUrl: 'https://karta.com.au',
    privacyUrl: 'https://karta.com.au',
    contactUsUrl: 'https://karta.com.au',
    copyright: 'Karta Gift Cards are issued by 545490 Pty. Ltd. ABN 83 648 605 225.',
    socials: [
      {
        type: SocialsType.FACEBOOK,
        url: '',
      },
      {
        type: SocialsType.LINKED_IN,
        url: '',
      },
      {
        type: SocialsType.TWITTER,
        url: '',
      },
      {
        type: SocialsType.INSTAGRAM,
        url: '',
      },
    ],
    appleIPhoneCardImageUrl: 'https://i.ibb.co/Y8V6VXk/card.png',
    appleIPhoneCardLogoUrl: 'https://i.ibb.co/sqkQ6P4/logomark.png',
    googleGetStartedUrl: 'https://karta.com.au',
  },
};

export const MCDONALDS_CONTENT: MerchantContent = {
  name: 'mcdonalds',
  data: {
    brandLogoUrl: 'https://assets.website-files.com/5f4772ef02f4161ae5ad5750/5f4ded43c4fca20738ebb6b5_McDonalds2.png',
    merchantWebsiteUrl: 'https://mcdonalds.com.au',
    merchantGiftCardsText: "McDonald's Gift Cards",
    termsAndConditionsUrl: 'https://mcdonalds.com.au',
    privacyUrl: 'https://mcdonalds.com.au',
    contactUsUrl: 'https://mcdonalds.com.au',
    copyright: 'McDonalds Gift Cards are issued by 545490 Pty. Ltd. ABN 83 648 605 225.',
    socials: [
      {
        type: SocialsType.FACEBOOK,
        url: '',
      },
      {
        type: SocialsType.LINKED_IN,
        url: '',
      },
      {
        type: SocialsType.TWITTER,
        url: '',
      },
      {
        type: SocialsType.INSTAGRAM,
        url: '',
      },
    ],
    appleIPhoneCardImageUrl: 'https://cdn.dev.karta.com.au/karta_b2b/appclips_jason2/card-artwork-front.png',
    appleIPhoneCardLogoUrl:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/McDonald%27s_Golden_Arches.svg/1200px-McDonald%27s_Golden_Arches.svg.png',
  },
};

export const MERCHANT_CONTENTS: MerchantContent[] = [KARTA_CONTENT, MCDONALDS_CONTENT];
