/* eslint-disable  import/no-unresolved */
import { responsiveFontSizes, createTheme, ThemeOptions } from '@material-ui/core';
import typography from './typography';
import { karta } from './palette';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { Overrides } from '@material-ui/core/styles/overrides';

const makeOverrides = (palette: PaletteOptions): Overrides => ({
  MuiCssBaseline: {
    '@global': {
      body: {
        fontFamily: 'Manrope',
        color: palette?.text?.primary,
        backgroundColor: palette.bodyBackground,
      },
      p: {
        fontSize: `${typography.large.fontSize}px`,
        lineHeight: `${typography.large.lineHeight}`,
        letterSpacing: `${typography.large.letterSpacing}`,
      },
      h1: {
        fontSize: `${typography.title1.fontSize}px`,
        lineHeight: `${typography.title1.lineHeight}`,
        letterSpacing: `${typography.title1.letterSpacing}`,
      },
      h2: {
        fontSize: `${typography.title2.fontSize}px`,
        lineHeight: `${typography.title2.lineHeight}`,
        letterSpacing: `${typography.title2.letterSpacing}`,
      },
      h3: {
        fontSize: `${typography.title3.fontSize}px`,
        lineHeight: `${typography.title3.lineHeight}`,
        letterSpacing: `${typography.title3.letterSpacing}`,
      },
    },
  },
  MuiAccordion: {
    root: {
      backgroundColor: palette?.background?.default,
      boxShadow: 'none',
      color: palette?.text?.primary,
      '&$expanded': {
        margin: 0,
      },
      '&::before': {
        height: 0,
      },
    },
  },
  MuiAccordionSummary: {
    root: {
      padding: 0,
    },
    content: {
      margin: 0,
      '&$expanded': {
        margin: 0,
      },
    },
  },
  MuiAccordionDetails: {
    root: {
      padding: 0,
      paddingBottom: 16,
    },
  },
  MuiTabs: {
    indicator: {
      display: 'none',
    },
  },
  MuiTab: {
    root: {
      textAlign: 'left',
      fontSize: 18,
      padding: '0 0 7px',
      lineHeight: '28px',
      minHeight: 0,
      textTransform: 'capitalize',
      '&$selected': {
        color: palette.primary,
      },
    },
    wrapped: {
      display: 'inline-block',
    },
  },
  MuiInputLabel: {
    shrink: {
      display: 'none',
    },
  },
  MuiButton: {
    root: {
      fontWeight: 'bold',
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 32,
      paddingRight: 32,
      textTransform: 'none',
    },
    text: {
      fontWeight: 'bold',
    },
  },
});

const BaseTheme: ThemeOptions = {
  typography,
  spacing: 8,
  shape: {
    borderRadius: 10,
  },
};

const DefaultTheme: ThemeOptions = {
  ...BaseTheme,
  overrides: makeOverrides(karta),
  palette: karta,
};

const themes = {
  default: responsiveFontSizes(createTheme(DefaultTheme)),
};

export default themes;
