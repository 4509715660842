import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tenant } from '../../types';

interface TenantState {
  tenantDetails: Tenant;
}

const initialState: TenantState = {
  tenantDetails: {
    tenantName: '',
    configuration: {
      appClip: {
        hideLogo: true,
      },
    },
  },
};

const { actions, reducer } = createSlice({
  name: 'TenantState',
  initialState,
  reducers: {
    setTenantDetails: (state, action: PayloadAction<Tenant>) => {
      const { payload } = action;
      state.tenantDetails = {
        ...state.tenantDetails,
        ...payload,
      };
    },
    resetTenantDetails: () => initialState,
  },
});

export const tenantActions = {
  ...actions,
};

export const tenantReducer = reducer;
