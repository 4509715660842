import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PurchaseState {
  selectedAmount: number;
  totalAmount: number;
  fees: number;
  gst: number;
  appClipTransactionId: string | null;
  loading?: boolean;
}

const initialState: PurchaseState = {
  selectedAmount: 0,
  totalAmount: 0,
  fees: 0,
  gst: 0,
  appClipTransactionId: null,
  loading: false,
};

const { actions, reducer } = createSlice({
  name: 'PurchaseState',
  initialState,
  reducers: {
    setSelectedAmount: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.selectedAmount = payload;
      state.totalAmount = payload + (state.fees ?? 0);
    },
    setTotalAmount: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.totalAmount = payload;
    },
    setFees: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.fees = payload;
      state.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      state.loading = payload;
    },
    setGst: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.gst = payload;
      state.loading = false;
    },
    setUUID4: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.appClipTransactionId = payload;
    },
    resetUUID4: (state) => {
      state.appClipTransactionId = null;
    },
  },
});

export const purchaseActions = {
  ...actions,
};

export const purchaseReducer = reducer;
