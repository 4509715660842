import firebase from 'firebase/app';
// import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

import 'firebase/functions';
import 'firebase/app-check';

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyD2k1I_kBYGSXdJX59nKDFd1N_r5-IWmzU',
  authDomain: 'karta-dev-b6368.firebaseapp.com',
  projectId: 'karta-dev-b6368',
  storageBucket: 'karta-dev-b6368.appspot.com',
  messagingSenderId: '204563205875',
  appId: '1:204563205875:web:2f9235053b1b89c43bb186',
  measurementId: 'G-QZQ9DQS4N1',
});
const firebaseFunctions = firebase.functions();

const appCheck = firebase.appCheck();
appCheck.activate('6LcjcksbAAAAAPOCbfyi-U7bsvgvwdxyxl3ckD2k');

/* Version 9
// See: https://firebase.google.com/docs/app-check/web/recaptcha-provider#web-version-9
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6LcjcksbAAAAAPOCbfyi-U7bsvgvwdxyxl3ckD2k'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});
console.debug('appCheck', appCheck);
*/

export { firebaseApp, firebaseFunctions };
