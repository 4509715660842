import { CircularProgress } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FC, useState } from 'react';
import {
  ErrorComponent,
  ErrorModalComponent,
  ErrorRiskModalComponent,
  SheetHeaderLabel,
} from '../../components/AppClip';
import { CheckoutForm, SelectPaymentMethod } from '../../components/AppClip/Payment';
import Flex from '../../components/Common/Flex';
import {
  AppConfig,
  FETCH_ERROR,
  GENERAL_ERRORS,
  Payment as PaymentConstants,
  RISK_API_ERRORS,
  RISK_FRAUD_RULE,
  RISK_KYC_RULE,
  SCREEN_IDS,
} from '../../constants';
import { RootState, screenActions, useAppDispatch, useAppSelector } from '../../store';
import { Containers } from '../../styles/AppClip/Containers';
import { IPaymentIntentResponse } from '../../types';

const { header, subheader } = PaymentConstants;

const styles = {
  loading: {
    color: '#310D55',
    alignSelf: 'center',
    justifyContent: 'center',
  },
};

const PaymentCardScreen: FC = () => {
  const dispatch = useAppDispatch();
  const { paymentForm } = useAppSelector((state: RootState) => state.payment);
  const { setScreen } = screenActions;
  const { selectAmountScreen, paymentCardScreen } = SCREEN_IDS;
  const [subHeadingText, setSubHeadingText] = useState(subheader);
  const [headingText, setHeadingText] = useState(header);
  const [isLoading, setIsLoading] = useState(false);
  const [errorID, setErrorID] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorLabel, setErrorLabel] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [errorBtnLabel, setErrorBtnLabel] = useState('');
  const { ErrorOnSubmit, ErrorOnPageLoad, ErrorSystemOnSubmit } = GENERAL_ERRORS;
  const { ErrorOnRiskBlocked, ErrorOnKycRequired } = RISK_API_ERRORS;
  const stripePromise = loadStripe(AppConfig.STRIPE_KEY as string);

  const onPressHandler = () => {
    setErrorID('');
    setErrorLabel('');
    setErrorBtnLabel('');
    setErrorMessage('');
    switch (errorID) {
      case ErrorOnSubmit.id:
        return console.log('Press me ErrorOnSubmit');
      case ErrorOnPageLoad.id:
        return dispatch(setScreen({ screen: paymentCardScreen, loading: false, updating: false }));
      case ErrorSystemOnSubmit.id:
        return dispatch(setScreen({ screen: selectAmountScreen, loading: false, updating: false }));
      default:
        return;
    }
  };

  const handlerFailed = (response: IPaymentIntentResponse) => {
    console.log('handlerFailed', response);
    if (response.clientSecret === '' && response.message === FETCH_ERROR) {
      //network related
      setErrorID(ErrorOnPageLoad.id);
      setErrorLabel(ErrorOnPageLoad.label);
      setErrorBtnLabel(ErrorOnPageLoad.buttonLabel);
      setErrorMessage(ErrorOnPageLoad.message);
      setIsLoading(false);
    } else if (response.message?.includes(RISK_FRAUD_RULE)) {
      //risk block related
      setErrorID(ErrorOnRiskBlocked.id);
      setErrorLabel(ErrorOnRiskBlocked.label);
      setErrorBtnLabel(ErrorOnRiskBlocked.buttonLabel);
      setErrorMessage(ErrorOnRiskBlocked.message);
      setIsLoading(false);
    } else if (response.message?.includes(RISK_KYC_RULE)) {
      //kyc related
      const errorResponse = JSON.parse(response.message);
      const orderNumber = errorResponse?.data?.orderNumber;
      setErrorID(ErrorOnKycRequired.id);
      setErrorLabel(ErrorOnKycRequired.label);
      setErrorBtnLabel(ErrorOnKycRequired.buttonLabel);
      setErrorMessage(ErrorOnKycRequired.message);
      setOrderNumber(orderNumber);
      setIsLoading(false);
    } else {
      //catch anything from cors related to BE
      setErrorID(ErrorSystemOnSubmit.id);
      setErrorLabel(ErrorSystemOnSubmit.label);
      setErrorBtnLabel(ErrorSystemOnSubmit.buttonLabel);
      setErrorMessage(ErrorSystemOnSubmit.message);
      setIsLoading(false);
    }
  };

  return errorID === ErrorOnPageLoad.id ? (
    <Flex style={Containers.column}>
      <ErrorComponent
        label={errorLabel}
        message={errorMessage}
        buttonLabel={errorBtnLabel}
        onPress={() => {
          onPressHandler();
        }}
      />
    </Flex>
  ) : (
    <Flex style={Containers.column} width="100%">
      <ErrorModalComponent
        open={errorID === ErrorOnSubmit.id || errorID === ErrorSystemOnSubmit.id}
        label={errorLabel}
        message={errorMessage}
        buttonLabel={errorBtnLabel}
        onPress={() => {
          onPressHandler();
        }}
      />
      <ErrorRiskModalComponent
        open={errorID === ErrorOnRiskBlocked.id || errorID === ErrorOnKycRequired.id}
        label={errorLabel}
        message={errorMessage}
        buttonLabel={errorBtnLabel}
        orderNumber={errorID === ErrorOnKycRequired.id ? orderNumber : ''}
        onPress={() => {
          onPressHandler();
        }}
      />
      <SheetHeaderLabel label={headingText} subLabel={subHeadingText} />
      {isLoading && (
        <Flex style={Containers.rowCenterCenter}>
          <CircularProgress size={'3rem'} style={styles.loading} />
        </Flex>
      )}
      {!isLoading && (
        <Elements stripe={stripePromise}>
          {paymentForm === 'select' ? (
            <SelectPaymentMethod
              setHeading={setHeadingText}
              setSubHeading={setSubHeadingText}
              setScreenLoading={setIsLoading}
              failCallback={handlerFailed}
            />
          ) : (
            <CheckoutForm setHeading={setHeadingText} setSubHeading={setSubHeadingText} failCallback={handlerFailed} />
          )}
        </Elements>
      )}
    </Flex>
  );
};

export default PaymentCardScreen;
