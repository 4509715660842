import { Box, styled } from '@material-ui/core';
import { PropsWithChildren } from 'react';
import { useAppClips } from './Context';

type CardProps = {
  url?: string;
  height: number;
  width: number;
  borderRadius?: number;
};

const Container = styled(Box)(({ url, height, width, borderRadius = 8 }: CardProps) => ({
  width,
  height,
  borderRadius,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundImage: `url('${url}')`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
}));

const GiftCard = ({ height, width, borderRadius }: PropsWithChildren<CardProps>): React.ReactElement | null => {
  const { merchantContent } = useAppClips();
  if (!merchantContent?.data?.appleIPhoneCardImageUrl) return null;
  return (
    <Container
      url={merchantContent.data.appleIPhoneCardImageUrl}
      height={height}
      width={width}
      borderRadius={borderRadius}
    >
      {merchantContent?.data?.appleIPhoneCardLogoUrl && (
        <img alt="iPhone" src={merchantContent.data.appleIPhoneCardLogoUrl} height="50%" />
      )}
    </Container>
  );
};
export default GiftCard;
