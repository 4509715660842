import { responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { Theme } from '@545490/karta-web-components';

const fontRubik = 'Rubik, Roboto, Open Sans, sans-serif';
const fontGilroy = 'Gilroy, Roboto, Open Sans, sans-serif';

const KartaTheme = createTheme({
  palette: {
    ...Theme.light,
    primary: {
      main: '#C6FFFF',
      light: '#E3FFFF',
      dark: '#2E2E2E',
      contrastText: '#000000',
    },
    secondary: {
      main: '#8C63B2',
      light: '#DEB5FF',
      dark: '#001F1A',
      contrastText: '#172136',
    },
    error: {
      main: '#e01e54',
      light: '#FC456B',
      dark: '#3B000D',
    },
    grey: {
      100: '#F7FAFF',
      400: '#F7FAFF',
    },
    text: {
      primary: '#172136',
      secondary: '#454545',
    },
    background: {
      paper: '#F7FAFF',
      default: '#C6FFFF',
    },
  },
  typography: {
    fontFamily: fontRubik,
    h4: {
      color: '#172136',
      fontFamily: fontGilroy,
      fontWeight: 700,
      fontSize: '34px',
      letterSpacing: 'normal',
    },
    h5: {
      color: '#172136',
      fontFamily: fontGilroy,
      fontWeight: 600,
      fontSize: '1.75rem',
      letterSpacing: 'normal',
    },
    h6: {
      color: '#61657B',
      fontFamily: fontRubik,
      fontWeight: 400,
      fontSize: '17px',
      letterSpacing: 'normal',
    },
    caption: {
      fontFamily: fontRubik,
      fontWeight: 400,
      fontSize: '0.75rem',
      letterSpacing: 'normal',
    },
    button: {
      color: '#FFFFFF',
      fontFamily: fontRubik,
      fontSize: '16px',
      fontWeight: 600,
      letterSpacing: 'normal',
      lineHeight: '21px',
      marginTop: 0,
      marginBottom: 0,
      textAlign: 'center',
      textTransform: 'capitalize',
    },
  },
});

export const AppClipThemes = {
  karta: responsiveFontSizes(KartaTheme),
};
