import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '../../styles/AppClip';
import Flex from '../Common/Flex';
import { FC } from 'react';
import { useIsLargerScreen, useIsSmallScreen } from '../../hooks/useIsMobileView';

interface PowerByFooterComponent {
  href?: string;
  textFooter?: string;
  imgSource?: string;
  show?: boolean;
}

const useStyles = makeStyles((theme) => ({
  footerLink: {
    textDecoration: 'none',
  },
  logoIcon: {
    width: '92.26px',
  },
  textFooter: {
    ...Typography.rubik.regular,
    opacity: '0.6',
    marginTop: '2.5rem',
    textAlign: 'center',
  },
  giftLogoIcon: {
    width: '158px',
    marginBottom: '10px',
  },
}));

const PowerByFooter: FC<PowerByFooterComponent> = ({ href, textFooter, imgSource, show }) => {
  const classes = useStyles();
  const isDesktop = useIsLargerScreen();
  const isSmall = useIsSmallScreen();
  return (
    <Flex
      alignContent={'center'}
      alignSelf="center"
      flexDirection="column"
      justifyContent="flex-end"
      width={'30%'}
      mb={3.5}
      position={'absolute'}
      bottom={isDesktop ? '10px' : isSmall ? -18 : '60px'}
    >
      <a href={href} className={classes.footerLink}>
        <Flex alignContent={'center'} alignItems={'center'} flexDirection={'column'}>
          {show ? <img className={classes.giftLogoIcon} src={'/gift-logo.png'} alt="gift Logo" /> : null}
          {textFooter ? <p className={classes.textFooter}>{textFooter}</p> : null}
          <img className={classes.logoIcon} src={imgSource} alt="Karta Logo" />
        </Flex>
      </a>
    </Flex>
  );
};

export default PowerByFooter;
