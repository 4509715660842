import { Box, BoxProps } from '@material-ui/core';
import { Typography } from '../../theme';
import { useIsMobileView } from '../../hooks/useIsMobileView';

export type TextProps = BoxProps & {
  bold?: boolean;
};

/**
 * Display font, a wrapper of Mui Box
 * @param props Mui Box props
 */
export const Display = (props: BoxProps): React.ReactElement => (
  <Box component="div" {...Typography[useIsMobileView() ? 'title1' : 'display']} {...props} />
);

/**
 * Title1 font, a wrapper of Mui Box
 * @param props Mui Box props
 */
export const Title1 = (props: BoxProps): React.ReactElement => (
  <Box component="h1" {...Typography[useIsMobileView() ? 'title2' : 'title1']} {...props} />
);

/**
 * Title2 font, a wrapper of Mui Box
 * @param props Mui Box props
 */
export const Title2 = (props: BoxProps): React.ReactElement => (
  <Box component="h2" {...Typography[useIsMobileView() ? 'title3' : 'title2']} {...props} />
);

/**
 * Title3 font, a wrapper of Mui Box
 * @param props Mui Box props
 */
export const Title3 = ({ bold, ...props }: TextProps): React.ReactElement => (
  <Box
    component="h3"
    {...Typography[useIsMobileView() ? 'large' : 'title3']}
    fontWeight={bold ? 'fontWeightBold' : 'fontWeightRegular'}
    {...props}
  />
);

/**
 * Large font, a wrapper of Mui Box
 * @param props Mui Box props
 * @param props.bold
 */
export const Large = ({ bold, ...props }: TextProps): React.ReactElement => (
  <Box component="p" {...Typography.large} fontWeight={bold ? 'fontWeightBold' : 'fontWeightRegular'} {...props} />
);

/**
 * Body font, a wrapper of Mui Box
 * @param props Mui Box props
 * @param props.bold
 */
export const Body = ({ bold, ...props }: TextProps): React.ReactElement => (
  <Box component="p" {...Typography.body} fontWeight={bold ? 'fontWeightBold' : 'fontWeightRegular'} {...props} />
);

/**
 * Small font, a wrapper of Mui Box
 * @param props Mui Box props
 * @param props.bold
 */
export const Small = ({ bold, ...props }: TextProps): React.ReactElement => (
  <Box component="p" {...Typography.small} fontWeight={bold ? 'fontWeightBold' : 'fontWeightRegular'} {...props} />
);
