/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { AppConfig } from '../constants';
import { useConnectContext } from '../contexts/ConnectContext';
import { persistor } from '../store';

const useCloseSession = (enable = true) => {
  const { toggleLeaveDialog } = useConnectContext();
  const enableDialog = useRef(enable);

  const handleTabClose = function (event: BeforeUnloadEvent) {
    const e = event || window.event;
    e.preventDefault();
    if (enable || enableDialog.current) {
      e.returnValue = 'Are you sure you want to leave your card purchase?';
      return e.returnValue;
    }
  };

  const handleUnload = function () {
    persistor.purge();
  };

  const handleTabBlur = function () {
    const { visibilityState } = document ?? {};
    if (visibilityState === 'hidden' && enableDialog.current) {
      toggleLeaveDialog(true);
    }
  };

  useEffect(() => {
    enableDialog.current = enable;
  }, [enable]);

  useEffect(() => {
    if (AppConfig.DISABLE_LEAVE_DIALOG) return;
    window.addEventListener('beforeunload', handleTabClose, true);
    window.addEventListener('visibilitychange', handleTabBlur, true);
    window.addEventListener('unload', handleUnload, true);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose, true);
      window.removeEventListener('visibilitychange', handleTabBlur, true);
      window.removeEventListener('unload', handleUnload, true);
    };
  }, []);
};

export default useCloseSession;
