import DateFnsUtils from '@date-io/date-fns';
import { Box, BoxProps, makeStyles } from '@material-ui/core';
import { TimePicker as MuiTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { format, isValid } from 'date-fns';
import { useEffect, useState } from 'react';
import { styles as ConstantStyles } from '../../constants/styles';
import Flex from '../Common/Flex';
import { TextFieldWithLabel } from './FormComponents';
import { PrimaryButton } from '@545490/karta-web-components';
import clockSvg from '../../images/clock.svg';

type Props = Omit<BoxProps, 'onChange'> & {
  label: string;
  description?: string;
  value?: Date | null;
  isOpen: boolean;
  changeOpen: (isOpen: boolean) => void;
  onChange: (date: Date | null) => void;
  onCancel: () => void;
  error?: string;
};

const useStyles = makeStyles((theme) => ({
  picker: {
    fontFamily: 'Rubik, Roboto, "Open Sans", sans-serif',
    '& .timepicker-section': {
      boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
      borderRadius: 8,
    },
    '& .MuiPickersStaticWrapper-staticWrapperRoot': {
      width: '100%',
      minWidth: 260,
      borderRadius: 8,
      [theme.breakpoints.up('sm')]: {
        minWidth: 310,
      },
      '& .MuiPickersBasePicker-pickerView': {
        minWidth: 260,
        [theme.breakpoints.up('sm')]: {
          minWidth: 310,
        },
      },
    },
    '& .MuiPickersBasePicker-container': {
      alignItems: 'center',
      '& .MuiPickersToolbar-toolbar': {
        width: '100%',
        padding: 0,
      },
      '& .MuiToolbar-root': {
        backgroundColor: 'transparent',
        '& .MuiPickersTimePickerToolbar-hourMinuteLabel': {
          '& .MuiPickersTimePickerToolbar-separator': {
            color: '#2E2E2E',
            height: 64,
            fontSize: 51.2,
            lineHeight: '56px',
            [theme.breakpoints.up('sm')]: {
              height: 80,
              fontSize: 64,
              lineHeight: '76px',
            },
          },
          '& .MuiButton-label': {
            '& .MuiPickersToolbarText-toolbarTxt': {
              backgroundColor: '#F7FAFF',
              color: '#2E2E2E',
              width: 76.8,
              height: 64,
              fontSize: 48,
              lineHeight: '60px',
              [theme.breakpoints.up('sm')]: {
                width: 96,
                height: 80,
                fontSize: 56,
                lineHeight: '80px',
              },
              '&.MuiPickersToolbarText-toolbarBtnSelected': {
                backgroundColor: '#EDF0FF',
              },
            },
          },
        },
        '& .MuiPickersTimePickerToolbar-ampmSelection': {
          border: '1px solid #DADCE0',
          borderRadius: 4,
          padding: 2,
          marginRight: 0,
          '& .MuiButtonBase-root': {
            width: 41.6,
            height: 32,
            [theme.breakpoints.up('sm')]: {
              width: 52,
              height: 40,
            },
            '&:first-of-type': {
              '& .MuiPickersTimePickerToolbar-ampmLabel': {
                '&.MuiPickersToolbarText-toolbarBtnSelected': {
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                },
              },
            },
            '&:last-of-type': {
              '& .MuiPickersTimePickerToolbar-ampmLabel': {
                '&.MuiPickersToolbarText-toolbarBtnSelected': {
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                },
              },
            },
            '& .MuiPickersTimePickerToolbar-ampmLabel': {
              color: '#2E2E2E',
              width: 41.6,
              height: 32,
              fontSize: 14,
              lineHeight: '30px',
              [theme.breakpoints.up('sm')]: {
                width: 52,
                height: 40,
                lineHeight: '36px',
              },
              '&.MuiPickersToolbarText-toolbarBtnSelected': {
                color: '#FFF',
                backgroundColor: '#2E2E2E',
              },
            },
          },
        },
      },
      display: 'flex',
      justifyContent: 'center' /* Horizontal centering */,
      '& .MuiPickersToolbarButton-toolbarBtn': {
        width: 76.8,
        height: 64,
        [theme.breakpoints.up('sm')]: {
          width: 96,
          height: 80,
        },
      },
      '& .MuiPickersClock-clock': {
        backgroundColor: '#EDF0FF',
      },
      '& .MuiPickersClock-container': {
        width: '100%',
        maxWidth: '100%',
        '& .MuiPickersClockPointer-pointer': {
          backgroundColor: '#2E2E2E',
        },
        '& .MuiPickersClockPointer-thumb': {
          backgroundColor: '#2E2E2E',
          borderColor: '#2E2E2E',
        },
      },
    },
  },
  text: {
    '& input': {
      width: '100%',
    },
    '& .text-field': {
      borderRadius: 8,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: 'transparent',
      '&:hover > div, &:focus > div': {
        borderColor: '#2E2E2E',
      },
    },
  },
  iconContainer: {
    position: 'absolute',
    top: '45%',
    display: 'inline-block',
    right: '15px',
  },
  icon: {
    width: ' 24px',
    height: '24px',
  },
}));

const TimePicker = ({ value, description, label, isOpen, error, changeOpen, onChange, onCancel, ...rest }: Props) => {
  const styles = useStyles();
  const [isDirty, setIsDirty] = useState(false);
  const [open, setOpen] = useState(false);
  const [openTo, setOpenTo] = useState<'hours' | 'minutes'>('hours');
  const [timeDisplay, setTimeDisplay] = useState<string | undefined>(!value ? undefined : format(value, 'hh:mm a'));
  const [date, setDate] = useState<Date | null | undefined>(value);

  const handleDateClicked = () => {
    setOpen(true);
    changeOpen(true);
  };

  const handleTimeChanged = (d: Date) => {
    setDate(d);
    setTimeDisplay(format(d, 'hh:mm a'));
  };

  const handleConfirmTime = () => {
    if (!date || !isValid(date)) return;
    onChange(date);
    setTimeDisplay(format(date, 'hh:mm a'));
    setIsDirty(true);
  };

  const handleCancel = () => {
    if (!value || !isValid(value)) return;
    setDate(value);
    setTimeDisplay(format(value, 'hh:mm a'));
    onCancel();
  };

  useEffect(() => {
    setDate(value);
    setTimeDisplay(value ? format(value ?? new Date(), 'hh:mm a') : '');
  }, [value]);

  useEffect(() => {
    setOpen(isOpen);
    if (isOpen) {
      setOpenTo('hours');
    } else {
      setDate(value);
      setTimeDisplay(value ? format(value ?? new Date(), 'hh:mm a') : '');
    }
  }, [isOpen]);

  return (
    <Box position="relative" width="100%" {...rest}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Flex className={styles.picker} flexDirection="column" alignItems="center" width="100%">
          <Box className={styles.text} position="relative" width="100%" onClick={handleDateClicked}>
            <TextFieldWithLabel
              containerStyle={{ width: '100%' }}
              id={label.toLowerCase().split(' ').join('-')}
              label={label}
              value={timeDisplay}
              readOnly
              placeholder="Select time"
              icon={clockSvg}
              errorMessage={isDirty ? error : undefined}
            />
          </Box>
          {open && (
            <Box className="timepicker-section" width="100%">
              <MuiTimePicker
                openTo={openTo}
                fullWidth
                variant="static"
                minutesStep={5}
                margin="normal"
                value={date}
                onChange={(d) => {
                  handleTimeChanged(d as Date);
                }}
              />
              <Flex justifyContent="space-between" py={2} px={2} bgcolor="#FFF" borderRadius={8}>
                <PrimaryButton
                  variant="outlined"
                  style={ConstantStyles.outlinedButton({ flex: '!0', marginTop: 0, padding: '6px 12px' })}
                  onClick={() => handleCancel()}
                  disableElevation
                >
                  Cancel
                </PrimaryButton>
                <PrimaryButton
                  variant="outlined"
                  style={ConstantStyles.outlinedButton({ flex: '!0', marginTop: 0, padding: '6px 12px' })}
                  onClick={() => handleConfirmTime()}
                  disableElevation
                >
                  Confirm
                </PrimaryButton>
              </Flex>
            </Box>
          )}
        </Flex>
      </MuiPickersUtilsProvider>
    </Box>
  );
};

export default TimePicker;
