import { FC } from 'react';

import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@mui/material/styles';

import Flex from '../Common/Flex';
import { Containers, fontGilroy, fontRubik } from '../../styles/AppClip';
interface IErrorModalComponent {
  label?: string;
  message?: string;
  orderNumber?: string;
  buttonLabel?: string;
  open?: boolean;
  onPress: () => void;
}
const useStyles = makeStyles((theme) => ({
  // Maybe style the scrollbar in the future
  root: {
    '&::-webkit-scrollbar': {
      width: '0px',
    },
    '&::-webkit-scrollbar-track': {},
    '&::-webkit-scrollbar-thumb': {},
  },
}));

const styles = {
  label: () => ({
    fontSize: 20,
    letterSpacing: 'normal',
    lineHeight: '30px',
    fontFamily: fontGilroy,
    fontWeight: 700,
    textAlign: 'left' as const,
    marginBottom: '16px',
  }),
  message: () => ({
    fontSize: 16,
    letterSpacing: 'normal',
    lineHeight: '21px',
    fontFamily: fontRubik,
    fontWeight: 400,
    textAlign: 'left' as const,
  }),
  orderNumber: () => ({
    marginTop: '16px',
    fontSize: 16,
    letterSpacing: 'normal',
    lineHeight: '21px',
    fontFamily: fontRubik,
    fontWeight: 400,
    textAlign: 'left' as const,
    color: '#61657B',
  }),
};

export const ErrorRiskModalComponent: FC<IErrorModalComponent> = ({
  label = '',
  message = '',
  orderNumber = '',
  open = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Modal open={open}>
      <Flex
        style={{
          ...Containers.column,
          height: 'auto',
          width: 'auto',
          maxWidth: '376px',
          maxHeight: 'max-content',
          justifyContent: 'flex-start',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          margin: 'auto',
          padding: '20px',
          borderRadius: '10px',
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Flex
          className={classes.root}
          style={{
            flexDirection: 'column',
            overflowY: 'scroll',
          }}
        >
          <span style={styles.label()}>{label}</span>
          <span style={styles.message()}>{message}</span>
          {orderNumber && (
            <span data-testid="kyc-modal-orderid" style={styles.orderNumber()}>
              Order #: {orderNumber}
            </span>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
