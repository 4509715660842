import { PaletteOptions } from '@material-ui/core/styles/createPalette';

export const base: PaletteOptions = {
  primary: {
    main: '#310d55',
    light: '#8C63B2',
    dark: '#13002c',
  },
  secondary: {
    main: '#08bea8',
    light: '#5ff1d9',
    dark: '##008d79',
  },
  borders: {
    divider: '#9D9FA2',
  },
  sectionBackground: '#F3F4FB',
  sectionHeadingTextColor: '#310d55',
  sectionBodyTextColor: '#606060',
  bodyBackground: '#FFF',
  headingTextColor: '#310d55',
  bodyTextColor: '#606060',
  stepItemBackground: '#310d55',
  stepItemColor: '#FFF',
};

export const karta: PaletteOptions = {
  type: 'light',
  ...base,
};
