import { FC } from 'react';

interface IRadioButtonUnchecked {
  color: string;
}

const RadioButtonUnchecked: FC<IRadioButtonUnchecked> = ({ color }) => {
  return (
    <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 1.75C5.89137 1.75 1.75 5.89137 1.75 11C1.75 16.1086 5.89137 20.25 11 20.25C16.1086 20.25 20.25 16.1086 20.25 11C20.25 5.89137 16.1086 1.75 11 1.75ZM0.25 11C0.25 5.06294 5.06294 0.25 11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11Z"
        fill={color}
      />
    </svg>
  );
};

export default RadioButtonUnchecked;
