import { FC } from 'react';
import Flex from '../Common/Flex';
import { ContainerProps } from './types';

const MobileView: FC<ContainerProps> = ({ children, backgroundColor }) => {
  return (
    <Flex bgcolor={backgroundColor} flex={1} flexDirection="column" minHeight={'100vh'} width={'100%'}>
      {children}
    </Flex>
  );
};

export default MobileView;
