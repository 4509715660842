import { CircularProgress } from '@material-ui/core';
import { FC } from 'react';
import Flex from '../Common/Flex';

interface IBlurComponent {
  height?: GLfloat;
}

export const BlurLoader: FC<IBlurComponent> = ({ height = 0 }) => {
  return (
    <Flex
      style={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height,
        width: 'auto',
        marginTop: -height,
        backdropFilter: 'blur(8px)',
        WebkitBackdropFilter: 'blur(8px)',
      }}
    >
      <CircularProgress
        style={{
          width: '32px',
          height: 'auto',
          alignSelf: 'center',
          color: '#310D55',
        }}
      />
    </Flex>
  );
};
