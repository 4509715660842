import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMerchantData } from '../../types';

interface MerchantState {
  merchantData: IMerchantData;
}

const initialState: MerchantState = {
  merchantData: {
    merchantLogoDark: '',
    merchantLogoDarkError: false,
    merchantLogoLight: '',
    merchantLogoLightError: false,
    merchantBackground: '',
    merchantBackgroundError: false,
    merchantCardArtWork: '',
    merchantNameUppercase: '',
    merchantName: '',
  },
};

const { actions, reducer } = createSlice({
  name: 'MerchantState',
  initialState,
  reducers: {
    setMerchantData: (state, action: PayloadAction<IMerchantData>) => {
      state.merchantData = action.payload;
    },
    reset: (state) => initialState,
  },
});

export const merchantActions = {
  ...actions,
};

export const merchantReducer = reducer;
