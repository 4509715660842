import { FC } from 'react';

interface IRadioButtonChecked {
  color: string;
}

const RadioButtonChecked: FC<IRadioButtonChecked> = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" fill={color} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12ZM12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM17.0303 10.0303C17.3232 9.73744 17.3232 9.26256 17.0303 8.96967C16.7374 8.67678 16.2626 8.67678 15.9697 8.96967L11 13.9393L9.03033 11.9697C8.73744 11.6768 8.26256 11.6768 7.96967 11.9697C7.67678 12.2626 7.67678 12.7374 7.96967 13.0303L10.4697 15.5303C10.6103 15.671 10.8011 15.75 11 15.75C11.1989 15.75 11.3897 15.671 11.5303 15.5303L17.0303 10.0303Z"
        fill={color}
      />
      <path
        d="M8 12L10.5 14.5L16 9"
        stroke="#FCF7FF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default RadioButtonChecked;
