import { FC } from 'react';
import { PrimaryButton } from '@545490/karta-web-components';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styles } from '../../constants/styles';
import Flex from '../Common/Flex';
import { ButtonSheet as ButtonSheetProps } from './types';

const ButtonSheet: FC<ButtonSheetProps> = ({
  label = '',
  withBack,
  disabled,
  disabledBack,
  buttonStyle = {},
  backButtonStyle = {},
  customLabel,
  isLoading = false,
  onPress,
  onPressBack,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Flex
      style={{
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      {withBack && (
        <PrimaryButton
          variant="contained"
          style={styles.backButton(backButtonStyle)}
          onClick={onPressBack}
          disabled={disabledBack}
          disableElevation
          data-testid={'back-button'}
        >
          <ArrowBack
            style={{
              color: 'white',
            }}
          />
        </PrimaryButton>
      )}
      <PrimaryButton
        variant="contained"
        style={styles.button(disabled ? { backgroundColor: '#959595', ...buttonStyle } : buttonStyle)}
        onClick={onPress}
        disableElevation
        disabled={disabled}
        {...props}
      >
        {isLoading && (
          <CircularProgress
            size={'1.5rem'}
            style={{
              color: 'white',
              marginRight: '8px',
            }}
          />
        )}
        <Box component="p" sx={theme.typography.button}>
          {label}
        </Box>
        {customLabel}
      </PrimaryButton>
    </Flex>
  );
};

export default ButtonSheet;
