import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PAYMENT_METHOD_VALUES } from '../../types';

interface PaymentState {
  clientSecret?: string;
  sender?: {
    firstName: string;
    lastName: string;
    name: string;
    mobile: string;
    mobileNumberDisplay?: string;
    email: string;
    firstNameErr?: string;
    lastNameErr?: string;
    emailErr?: string;
    mobileNumberErr?: string;
  };
  paymentForm?: string;
  paymentMethod?: string;
  paymentErrorResponse?: { clientSecret: string | null; message?: string };
}

const initialState: PaymentState = {
  clientSecret: '',
  sender: {
    firstName: '',
    lastName: '',
    name: '',
    mobile: '',
    mobileNumberDisplay: '',
    email: '',
    firstNameErr: '',
    lastNameErr: '',
    emailErr: '',
    mobileNumberErr: '',
  },
  paymentForm: 'select',
  paymentMethod: PAYMENT_METHOD_VALUES.CARD,
};

const { actions, reducer } = createSlice({
  name: 'PaymentState',
  initialState,
  reducers: {
    setPayment: (state, action: PayloadAction<PaymentState>) => {
      const { payload } = action;

      if (Object.keys(payload).includes('paymentMethod')) {
        state.paymentMethod = payload.paymentMethod;
      }

      if (payload.clientSecret) {
        state.clientSecret = payload.clientSecret;
      }
      if (payload.sender) {
        state.sender = payload.sender;
      }
      if (payload.paymentForm) {
        state.paymentForm = payload.paymentForm;
      }
      if (payload.paymentErrorResponse) {
        state.paymentErrorResponse = payload.paymentErrorResponse;
      }
    },
    resetPayment: () => initialState,
  },
});

export const paymentActions = {
  ...actions,
};

export const paymentReducer = reducer;
