import { SCREEN_IDS } from './ids';

export const Screens: { [key: string]: number } = {
  [SCREEN_IDS.selectAmountScreen]: 1,
  [SCREEN_IDS.recipientsDetailScreen]: 2,
  [SCREEN_IDS.scheduleDeliveryScreen]: 3,
  [SCREEN_IDS.reviewOrderScreen]: 4,
  [SCREEN_IDS.paymentCardScreen]: 5,
  [SCREEN_IDS.orderSuccessScreen]: 5,
};
