import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';

const SimpleDialog = ({ open }: { open: boolean }): React.ReactElement => {
  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Sending SMS...</DialogTitle>
      <DialogContent>
        <DialogContentText>Sending SMS...</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default SimpleDialog;
