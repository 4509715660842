import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import AppleBanner from './AppleBanner';
import CardSetup from './CardSetup';
import { AppClipsContextProvider, useAppClips } from './Context';
import Faqs from './Faqs';
import Footer from './Footer';
import Header from './Header';
import HowToUse from './HowToUse';

const Content = (): React.ReactElement => (
  <>
    <Header mb={3} />
    <AppleBanner />
    <CardSetup />
    <HowToUse />
    <Faqs />
    <Footer />
  </>
);

const Themed = (): React.ReactElement => {
  const { getThemeForMerchant } = useAppClips();
  return (
    <ThemeProvider theme={getThemeForMerchant()}>
      <CssBaseline />
      <Content />
    </ThemeProvider>
  );
};

const ApplePage = (): React.ReactElement => (
  <AppClipsContextProvider>
    <Themed />
  </AppClipsContextProvider>
);

export default ApplePage;
