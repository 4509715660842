export const fontRubik = 'Rubik, Roboto, Open Sans, sans-serif';
export const fontGilroy = 'Gilroy, Roboto, Open Sans, sans-serif';

export const Typography = {
  rubik: {
    regular: {
      color: '#172136',
      fontFamily: fontRubik,
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    semiBold: {
      color: '#172136',
      fontFamily: fontRubik,
      fontSize: '16px',
      fontWeight: 600,
    },
    bold: {
      color: '#172136',
      fontFamily: fontRubik,
      fontSize: '0.75rem',
      fontWeight: 700,
    },
  },
  gilroy: {
    regular: {
      color: '#172136',
      fontFamily: fontGilroy,
      fontSize: '13px',
      fontWeight: 300,
    },
    semiBold: {
      color: '#172136',
      fontFamily: fontGilroy,
      fontSize: '20px',
      fontWeight: 600,
    },
    bold: {
      color: '#172136',
      fontFamily: fontGilroy,
      fontSize: '34px',
      fontWeight: 700,
    },
  },
};
