import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import { useIsLargerScreen } from '../../hooks/useIsMobileView';
import Flex from '../Common/Flex';
import { RibbonProps } from './types';

const useStyles = makeStyles((theme) => ({
  leftRibbon: {
    height: '100%',
    width: 'auto',
  },
  rightRibbon: {
    height: '100%',
    width: 'auto',
  },
}));

const Ribbons: FC<RibbonProps> = ({
  height = '199.92px',
  leftRibbonSource = '/left-ribbon.png',
  rightRibbonSource = '/right-ribbon.png',
  leftTopDesktop,
  leftTopMobile,
  rightTopDesktop,
  rightTopMobile,
}) => {
  const classes = useStyles();
  const leftRibbonTopDesktop = leftTopDesktop || 21.22;
  const leftRibbonTopMobile = leftTopMobile || 169.75;
  const rightRibbonTopDesktop = rightTopDesktop || 330;
  const rightRibbonTopMobile = rightTopMobile || 478.66;
  return (
    <Flex>
      <Flex
        width={'auto'}
        height={height}
        position={'absolute'}
        left={0}
        top={useIsLargerScreen() ? leftRibbonTopDesktop : leftRibbonTopMobile}
      >
        <img className={classes.leftRibbon} src={leftRibbonSource} alt="Left Ribbon" />
      </Flex>
      <Flex
        width={'auto'}
        height={height}
        position="absolute"
        right={0}
        top={useIsLargerScreen() ? rightRibbonTopDesktop : rightRibbonTopMobile}
      >
        <img className={classes.rightRibbon} src={rightRibbonSource} alt="Right Ribbon" />
      </Flex>
    </Flex>
  );
};

export default Ribbons;
