import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import AuFlag from '../../images/flag-au.png';
import { Box, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Containers } from '../../styles/AppClip';
import Flex from '../Common/Flex';
import {
  ErrorMessageProps,
  IStyleProps,
  LabelProps,
  TextAreaProps,
  TextFieldProps,
  TextFieldWithLabelProps,
} from './types';

const useStyles = (props: IStyleProps) =>
  makeStyles((theme) => ({
    inputLabel: {
      color: props.theme.palette.text.primary,
      fontFamily: props.theme.typography.fontFamily,
      fontSize: '15px',
      letterSpacing: 'normal',
      lineHeight: '20px',
      marginTop: 0,
      paddingLeft: 8,
      marginBottom: 4,
    },
    input: {
      color: props.theme.palette.text.primary,
      fontFamily: props.theme.typography.fontFamily,
      fontSize: '17px',
      letterSpacing: 'normal',
      lineHeight: '22px',
      fontWeight: 400,
      padding: '8.5px 0px 7.5px 13px',
      border: '0px',
      outline: 'none',
      width: '100%',
      backgroundColor: props.theme.palette.grey[100],
    },
    inputWithImage: {
      color: props.theme.palette.text.primary,
      fontFamily: props.theme.typography.fontFamily,
      fontSize: 17,
      lineHeight: '22px',
      padding: '8.5px 0px 7.5px 13px',
      border: '0px',
      outline: 'none',
      width: '100%',
      backgroundColor: props.theme.palette.common.white,
    },
  }));

const styles = {
  container: {
    ...Containers.column,
    marginBottom: '8px',
  },
  countryCode: (theme: Theme) => ({
    ...theme.typography.caption,
    color: theme.palette.text.primary,
    fontSize: '17px',
    fontWeight: 400,
    lineHeight: '22px',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: '10px',
  }),
  countryCodeContainer: (theme: Theme) => ({
    alignItems: 'center',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.grey[100]}`,
    borderRadius: '10px',
    paddingTop: '9px',
    paddingBottom: '10px',
    paddingLeft: 12,
    paddingRight: 16,
    marginRight: 8,
  }),
  errorMessage: (theme: Theme) => ({
    ...theme.typography.caption,
    color: 'error.main',
    fontSize: '0.7rem',
    alignSelf: 'flex-end' as const,
    textAlign: 'right' as const,
    marginTop: '0px',
    marginBottom: 0,
    marginRight: '8px',
  }),
  textFieldMethodContainer: (borderColor: string, backgroundColor: string, customStyle = {}) => ({
    border: `2px solid ${borderColor}`,
    borderRadius: '10px',
    padding: '1px 8px 1px 3px',
    backgroundColor,
    ...customStyle,
  }),
};

const getTextFieldContainer = (
  theme: Theme,
  isFocused = false,
  errorMessage = '',
  revertColor = false,
  customStyle = {},
) => {
  const { primary, error, grey, common } = theme.palette;
  const unselectedColor = !revertColor ? grey[100] : common.white;
  const focusColor = isFocused ? primary.dark : unselectedColor;
  const borderColor = errorMessage ? error.main : focusColor;

  return styles.textFieldMethodContainer(borderColor, unselectedColor, customStyle);
};

const ErrorMessage: FC<ErrorMessageProps> = ({ errorMessage = '', ...props }) => {
  const theme = useTheme();

  return errorMessage ? (
    <Box component="p" sx={styles.errorMessage(theme)} {...props}>
      {errorMessage}
    </Box>
  ) : null;
};

const TextField: FC<TextFieldProps> = ({
  errorMessage = '',
  isFocusField = false,
  revertColor = false,
  className,
  icon,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme })();

  return (
    <Flex style={styles.container} className={className}>
      <Flex style={getTextFieldContainer(theme, isFocusField, errorMessage, revertColor)}>
        <input className={classes.input} {...props} />
        {icon && (
          <Box
            sx={{
              display: 'flex',
              alignSelf: 'center',
              marginRight: '7px',
              '& img': {
                width: ' 24px',
                height: '24px',
                cursor: 'text',
              },
            }}
          >
            <img src={icon} data-testid={`input-icon-${props.id}`} />
          </Box>
        )}
      </Flex>
      <ErrorMessage errorMessage={errorMessage} data-testid={`input-error-message-${props.id}`} />
    </Flex>
  );
};

const TextFieldWithImage: FC<TextFieldProps> = ({
  images = [],
  isFocusField,
  errorMessage = '',
  revertColor = false,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme })();

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <Flex style={getTextFieldContainer(theme, isFocusField, errorMessage, revertColor)}>
        <input className={classes.inputWithImage} color={'transparent'} {...props} />
        <Flex
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {images.map((step, index) => (
            <img
              key={`image${index}`}
              src={step}
              alt={''}
              width={'31.17px'}
              height={'22px'}
              style={{
                marginLeft: index === 0 || index === images.length - 1 ? '8px' : '0px',
                marginRight: index === 0 || index === images.length - 1 ? '8px' : '0px',
              }}
            />
          ))}
        </Flex>
      </Flex>
      <ErrorMessage errorMessage={errorMessage} />
    </Flex>
  );
};

const Label: FC<LabelProps> = ({ htmlFor, label }) => {
  const theme = useTheme();
  const classes = useStyles({ theme })();

  return (
    <label htmlFor={htmlFor} className={classes.inputLabel} data-testid={`input-label-${htmlFor}`}>
      {label}
    </label>
  );
};

const TextFieldWithLabel: FC<TextFieldWithLabelProps> = ({ label, htmlFor, containerStyle, ...props }) => {
  return (
    <Flex
      style={{
        ...containerStyle,
        flexDirection: 'column',
      }}
      data-testid={`input-container-${props.testId}`}
    >
      <Label htmlFor={htmlFor} label={label} />
      <TextField className="text-field" {...props} data-testid={`input-${props.id}`} />
    </Flex>
  );
};

const MobileNumber: FC<TextFieldWithLabelProps> = ({ label, htmlFor, containerStyle, ...props }) => {
  const theme = useTheme();

  return (
    <Flex
      style={{
        ...containerStyle,
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Label htmlFor={htmlFor} label={label} />
      <Flex
        style={{
          flex: 1,
          flexDirection: 'row',
        }}
      >
        <Flex style={styles.countryCodeContainer(theme)}>
          <img src={AuFlag} width={'24px'} height={'24px'} alt="AU logo" data-testid={'mobile-country-logo'} />
          <Box component="p" sx={styles.countryCode(theme)} data-testid={'mobile-country-code'}>
            +61
          </Box>
        </Flex>
        <Flex
          style={{
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <TextField {...props} data-testid={'input-mobile'} />
        </Flex>
      </Flex>
    </Flex>
  );
};

const TextArea: FC<TextAreaProps> = ({ errorMessage, isFocusField, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ theme })();

  const customStyle = { height: '111px', paddingTop: '4px' };

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <Flex style={getTextFieldContainer(theme, isFocusField, errorMessage, false, customStyle)}>
        <textarea className={classes.input} {...props} data-testid={`textarea-${props.id}`} />
      </Flex>
      <ErrorMessage errorMessage={errorMessage} data-testid={`textarea-error-message-${props.id}`} />
    </Flex>
  );
};

export { ErrorMessage, Label, MobileNumber, TextArea, TextField, TextFieldWithImage, TextFieldWithLabel };
