export enum SocialsType {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  LINKED_IN = 'linkedin',
  TWITTER = 'twitter',
}

export type MerchantSocialsInfo = {
  type: SocialsType;
  url?: string;
};

export type MerchantContentData = {
  merchantWebsiteUrl?: string;
  merchantGiftCardsText?: string;
  brandLogoUrl?: string;
  termsAndConditionsUrl?: string;
  privacyUrl?: string;
  contactUsUrl?: string;
  copyright?: string;
  appleCopyright?: string;
  socials?: MerchantSocialsInfo[];
  appleTitle?: string;
  appleSubtitle?: string;
  appleSetupHeading?: string;
  appleSetupSteps?: string[];
  applePayHeading?: string;
  applePayDescription?: string;
  appleIphoneHeading?: string;
  appleIphoneDescription?: string;
  appleWatchHeading?: string;
  appleWatchDescription?: string;
  applePayCaptions?: string[];
  applePayFaqHeading?: string;
  applePayFaqItems?: {
    title: string;
    description: string;
  }[];
  applePayFaqVideos?: {
    title: string;
    url: string;
  }[];
  appleIPhoneCardImageUrl?: string;
  appleIPhoneCardLogoUrl?: string;
  googleTitle?: string;
  googleSubtitle?: string;
  googleGetStartedUrl?: string;
};

export type MerchantContent = {
  name: string;
  data: MerchantContentData;
};
