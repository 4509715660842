import { Box, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { AppConfig, TermsConditions } from '../../constants';

const styles = {
  TermsStyle: (theme: Theme) => ({
    ...theme.typography.caption,
    color: 'common.black',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center' as const,
    marginTop: 1.5,
    marginBottom: 0,
  }),
  HyperLink: (theme: Theme) => ({
    ...theme.typography.caption,
    color: 'common.black',
    fontWeight: 'bold',
    fontSize: '12px',
    textDecoration: 'underline',
    marginTop: '0px',
    marginBottom: '0px',
  }),
};

interface IHyperLink {
  url?: string;
  text?: string;
}

export const TermsCondition = () => {
  const theme = useTheme();

  const HyperLink: FC<IHyperLink> = ({ url, text }) => {
    return (
      <Box component="a" sx={styles.HyperLink} href={url} target="_blank" rel="noreferrer">
        {text}
      </Box>
    );
  };
  return (
    <Box component="p" sx={styles.TermsStyle(theme)} data-testid="checkout-form-tc-text">
      By continuing, I understand and agree to the{' '}
      <HyperLink
        url={AppConfig.PRIVACY_URL}
        text={TermsConditions.privacy.text}
        data-testid="checkout-form-privacy-link"
      />{' '}
      and the{' '}
      <HyperLink
        url={AppConfig.TERMS_SERVICE_URL}
        text={TermsConditions.tnc.text}
        data-testid="checkout-form-terms-link"
      />{' '}
      and that I am authorised to provide information on behalf of the recipient.
    </Box>
  );
};
