import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import { setBottomSheetPosition, borderRadiusSheetTopCorners as topRadius } from '../../constants';
import { useIsLargerScreen } from '../../hooks/useIsMobileView';
import { Containers } from '../../styles/AppClip';
import Flex from '../Common/Flex';
import { BottomSheetProps } from './types';

const styles = {
  desktop: (_ = '', loading: boolean) => ({
    ...Containers.column,
    alignItems: loading ? 'center' : 'flex-start',
    borderRadius: 22,
    backgroundColor: '#fff',
    overflowX: 'hidden' as const,
    overflowY: 'scroll' as const,
    width: 458,
    padding: '43px',
    zIndex: 3,
    boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
    marginBottom: 64,
  }),
  mobile: (screen = '', loading: boolean) => ({
    ...Containers.column,
    alignItems: loading ? 'center' : 'flex-start',
    marginTop: setBottomSheetPosition(screen, loading, false),
    borderTopLeftRadius: topRadius,
    borderTopRightRadius: topRadius,
    padding: '0px 16px 12px 16px',
    backgroundColor: '#fff',
    overflowY: 'scroll' as const,
    zIndex: 3,
  }),
};

const useStyles = makeStyles((theme) => ({
  // Maybe style the scrollbar in the future
  root: {
    '&::-webkit-scrollbar': {
      width: '0px',
    },
    '&::-webkit-scrollbar-track': {},
    '&::-webkit-scrollbar-thumb': {},
  },
}));

export const BottomSheet: FC<BottomSheetProps> = ({
  screen = '',
  loading = false,
  children,
  contentContainerStyle = {},
}) => {
  const isDesktop = useIsLargerScreen();
  const classes = useStyles();
  const { desktop, mobile } = styles;
  const containerStyle = isDesktop ? desktop(screen, loading) : mobile(screen, loading);
  return (
    <Flex className={classes.root} style={{ ...containerStyle, ...(contentContainerStyle ?? {}) }}>
      {loading ? <CircularProgress /> : children}
    </Flex>
  );
};
