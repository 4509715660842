import { useMediaQuery, useTheme } from '@material-ui/core';

export const useIsMobileView = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};

export const useIsLargerScreen = (): boolean => {
  return useMediaQuery('(min-width:500px)');
};

export const useIsSmallScreen = (): boolean => {
  /* return true for mobile browsers smaller than 375px */
  return useMediaQuery('(max-width:375px)');
};
