import { Box, BoxProps, Container, ITheme, styled } from '@material-ui/core';
import { Hidden } from '@mui/material';
import Flex from '../Common/Flex';
import { Large, Title1 } from '../Common/Text';
import { useAppClips } from './Context';

const StepIndicator = styled(Box)(({ theme }: { theme: ITheme }) => ({
  backgroundColor: theme.palette.stepItemBackground,
  borderRadius: 64,
  fontSize: 35,
  lineHeight: '47.81px',
  fontWeight: 'bold',
  color: theme.palette.stepItemColor,
  width: 64,
  minWidth: 64,
  height: 64,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: 32,
    minWidth: 32,
    height: 32,
    fontSize: 18,
  },
}));

type StepIndicatorLineProps = BoxProps & {
  transparent?: boolean;
};

const getSetupSteps = (merchantName: string) => {
  return [
    'Click on the link in the SMS',
    `Follow the prompts to add your ${merchantName} Gift Card to the app`,
    "Tap the 'Add to Apple Wallet button' to add your card to Apple Wallet.",
    'We may verify your identity via SMS/email as part of the setup',
    'If you have an Apple Watch, you will be prompted to set this up at the end',
  ];
};

const CardSetupSection = styled(Box)(({ theme }: { theme: ITheme }) => ({
  width: '100%',
  padding: `${theme.spacing(2.625)}px ${theme.spacing(1)}px`,
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(2.625)}px ${theme.spacing(1)}px`,
  },
}));

const StepIndicatorLine = styled((props: StepIndicatorLineProps) => <Box {...props} />)(
  ({ theme, transparent }: { theme: ITheme; transparent?: boolean }) => ({
    width: '100%',
    height: 3,
    background: !transparent ? theme.palette.stepItemBackground : 'transparent',
  }),
);

const CardSetup = (): React.ReactElement => {
  const { merchantContent } = useAppClips();
  const setupSteps = getSetupSteps(merchantContent.name);
  return (
    <CardSetupSection>
      <Container>
        <Title1 mb={{ xs: 3, md: 8 }} mx="auto" maxWidth={700} textAlign="center" color="headingTextColor">
          How to set your card in the {merchantContent.name} app and add it to Apple Wallet.
        </Title1>
        <Flex
          justifyContent="center"
          width="100%"
          mb={5.25}
          maxWidth={{ xs: '100%', md: 990 }}
          mx="auto"
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          {setupSteps.map((step, i) => (
            <Box
              width={{
                xs: '100%',
                md: `${100 / (setupSteps.length ?? 1)}%`,
              }}
              key={i}
            >
              <Hidden smDown implementation="css">
                <Flex alignItems="center" mb={1.125} justifyContent="space-evenly">
                  <StepIndicatorLine transparent={i === 0} />
                  <StepIndicator>{i + 1}</StepIndicator>
                  <StepIndicatorLine transparent={i === (setupSteps.length ?? 1) - 1} />
                </Flex>
              </Hidden>
              <Box mx={1} mb={{ xs: 2, md: 0 }}>
                <Flex>
                  <Hidden mdUp implementation="css">
                    <StepIndicator>{i + 1}</StepIndicator>
                  </Hidden>
                  <Large ml={{ xs: 2, md: 0 }} my={0} color="bodyTextColor" textAlign={{ xs: 'left', md: 'center' }}>
                    {step}
                  </Large>
                </Flex>
              </Box>
            </Box>
          ))}
        </Flex>
      </Container>
    </CardSetupSection>
  );
};

export default CardSetup;
