import { FIELD_IDS } from './ids';

export const COUNTRY_CODE = '+61';
export const REGEX_PATTERNS = {
  name: /^[a-zA-Z][a-zA-Z .'-]/,
  mobileNumber: /^[+]?[4][0-9]{2}?[s]?[0-9]{3}[s]?[0-9]{3,6}$/,
  mobileNumberShort: /^[4][0-9]{2}?[s]?[0-9]{3}[s]?[0-9]{3}$/,
  // eslint-disable-next-line no-useless-escape
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/,
};

export const MAX_CHARACTERS = 140;

export const PAYMENT_CARD_FIELDS = [
  {
    id: FIELD_IDS.card.firstCardNameId,
    placeholder: 'First name',
    type: 'text',
    maxLength: 30,
    autoComplete: 'given-name',
    pattern: REGEX_PATTERNS.name,
  },
  {
    id: FIELD_IDS.card.lastCardNameId,
    placeholder: 'Last name',
    type: 'text',
    maxLength: 30,
    autoComplete: 'family-name',
    pattern: REGEX_PATTERNS.name,
  },
  {
    id: FIELD_IDS.card.emailCardId,
    placeholder: 'Email address',
    type: 'email',
    minLength: 3,
    maxLength: 70,
    autoComplete: 'email',
    pattern: REGEX_PATTERNS.email,
    dataTestId: 'sender-email-address-field',
  },
  {
    id: FIELD_IDS.card.mobileCardNumberId,
    placeholder: 'Mobile number',
    type: 'tel',
    maxLength: 11,
    autoComplete: 'tel',
    pattern: REGEX_PATTERNS.mobileNumberShort,
  },
];
