import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SCREEN_IDS, FIELD_IDS } from '../../constants';
import { IScreenProps } from '../../types';

const { firstNameId, lastNameId, mobileNumberId, giftMessageId } = FIELD_IDS.recipient;
const {
  firstCardNameId,
  lastCardNameId,
  emailCardId,
  mobileCardNumberId,
  cardNumberId,
  expirationCardDateId,
  securityCardCodeId,
} = FIELD_IDS.card;
interface ScreentState {
  screenStates: IScreenProps;
}

const initialState: ScreentState = {
  screenStates: {
    screen: SCREEN_IDS.selectAmountScreen,
    loading: false,
    updating: false,
    isCreditCard: 0,
    isExpansion: false,
    fieldsIsFocus: {
      recipientDetails: {
        fields: {
          [firstNameId]: false,
          [lastNameId]: false,
          [mobileNumberId]: false,
          [giftMessageId]: false,
        },
      },
      CardDetails: {
        fields: {
          [firstCardNameId]: false,
          [lastCardNameId]: false,
          [emailCardId]: false,
          [mobileCardNumberId]: false,
          [cardNumberId]: false,
          [expirationCardDateId]: false,
          [securityCardCodeId]: false,
        },
      },
    },
  },
};

const { actions, reducer } = createSlice({
  name: 'ScreentState',
  initialState,
  reducers: {
    setScreen: (state, action: PayloadAction<IScreenProps>) => {
      const { payload } = action;
      const { screenStates } = state;
      state.screenStates = {
        ...screenStates,
        ...payload,
      };
    },
  },
});

export const screenActions = {
  ...actions,
};

export const screenReducer = reducer;
