import { Box, BoxProps } from '@material-ui/core';
import AppleIPhone from '../../images/apple-iphone.png';
import { useAppClips } from './Context';
import GiftCard from './GiftCard';

type IPhoneTemplateProps = BoxProps & {
  height: number;
  width: number;
  cardHeight: number;
  cardWidth: number;
};

const IPhoneTemplate = ({
  height,
  width,
  cardHeight,
  cardWidth,
  ...props
}: IPhoneTemplateProps): React.ReactElement => {
  const { merchantContent } = useAppClips();
  return (
    <Box {...props}>
      <Box position="relative" width={width}>
        <img src={AppleIPhone} alt="iPhone" width={width} height={height} />
        {merchantContent?.data?.appleIPhoneCardImageUrl && (
          <Box position="absolute" top="10%" left="12%" right="12%">
            <GiftCard height={cardHeight} width={cardWidth}>
              {merchantContent?.data?.appleIPhoneCardLogoUrl && (
                <img alt="iPhone" src={merchantContent.data.appleIPhoneCardLogoUrl} height="50%" />
              )}
            </GiftCard>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default IPhoneTemplate;
