import { useEffect } from 'react';
import { STRIPE_3D_SECURE_POST_MESSAGE } from '../constants';

const PaymentRedirect = () => {
  useEffect(() => {
    window.top?.postMessage(STRIPE_3D_SECURE_POST_MESSAGE);
  }, []);

  return <></>;
};

export default PaymentRedirect;
