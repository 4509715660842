import { FC } from 'react';
import { PrimaryButton } from '@545490/karta-web-components';
import { Grid } from '@material-ui/core';
import { Box, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ButtonSheet, SheetHeaderLabel } from '../../components/AppClip';
import Flex from '../../components/Common/Flex';
import {
  Buttons,
  SCREEN_IDS,
  SelectAmount as SelectAmountConstants,
  setMarginSelectedAmountBig,
} from '../../constants';
import { useIsLargerScreen } from '../../hooks';
import { RootState, purchaseActions, screenActions, useAppDispatch, useAppSelector } from '../../store';
import { Containers } from '../../styles/AppClip';

const { setSelectedAmount: setSelectedAmountRedux } = purchaseActions;
const { setScreen } = screenActions;
const { recipientsDetailScreen, reviewOrderScreen } = SCREEN_IDS;
const { header, amounts: AMOUNTS } = SelectAmountConstants;

const styles = {
  amountContainer: (isSelected: boolean, theme: Theme) => ({
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: isSelected ? '#2E2E2E' : '#F7FAFF',
    justifyContent: 'center',
    paddingTop: 19,
    paddingBottom: 17,
    borderRadius: 10,
    width: '100%',
    ...(isSelected && { border: '1px solid #2E2E2E' }),
    ...(!isSelected && { border: '1px solid #F0F0F0' }),
  }),
  selectedAmountBig: (theme: Theme, marginVertical: string) => ({
    ...theme.typography.h4,
    color: 'text.secondary',
    fontSize: '34px',
    lineHeight: '41px',
    marginTop: marginVertical,
    marginBottom: marginVertical,
    textAlign: 'center' as const,
  }),
  selectedAmount: (theme: Theme) => ({
    ...theme.typography.h5,
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: '15px',
    lineHeight: '20px',
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'center' as const,
  }),
  unselectedAmount: (theme: Theme) => ({
    ...theme.typography.caption,
    color: theme.palette.common.black,
    fontSize: '15px',
    lineHeight: '20px',
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'center' as const,
  }),
};

const SelectAmount: FC = () => {
  const isDesktop = useIsLargerScreen();
  const theme = useTheme();
  const marginVertical = setMarginSelectedAmountBig(isDesktop) + 'px';
  const dispatch = useAppDispatch();
  const selectedAmount = useAppSelector((state: RootState) => state.purchase.selectedAmount);
  const { updating: isUpdateFlow } = useAppSelector((state: RootState) => state.screen.screenStates);

  const onClickAmount = (amount: number) => {
    dispatch(setSelectedAmountRedux(amount));
  };

  const onClickContinue = () => {
    if (isUpdateFlow) {
      dispatch(setScreen({ screen: reviewOrderScreen, loading: false, updating: false }));
    }
    if (!isUpdateFlow) {
      dispatch(setScreen({ screen: recipientsDetailScreen, loading: false, updating: false }));
    }
  };

  return (
    <Flex style={Containers.column}>
      <SheetHeaderLabel label={header} />
      <Box
        data-testid="selected-amount-text"
        component="p"
        sx={{
          ...styles.selectedAmountBig(theme, marginVertical),
          marginTop: 1.5,
          marginBottom: 4.5,
        }}
      >
        {`$${selectedAmount.toFixed(2)}`}
      </Box>
      <Grid container spacing={2}>
        {AMOUNTS.map((amount, index) => {
          const isSelected = selectedAmount === amount;
          return (
            <Grid item xs={4} sm={4} md={4} key={index}>
              <PrimaryButton
                data-testid={`select-amount-button-$${amount}`}
                variant="contained"
                style={styles.amountContainer(isSelected, theme)}
                disableElevation
                onClick={() => onClickAmount(amount)}
              >
                <p style={isSelected ? styles.selectedAmount(theme) : styles.unselectedAmount(theme)}>{`$${amount}`}</p>
              </PrimaryButton>
            </Grid>
          );
        })}
      </Grid>
      <ButtonSheet
        data-testid="select-amount-screen-continue-button"
        label={isUpdateFlow ? Buttons.update : Buttons.continue}
        onPress={onClickContinue}
      />
    </Flex>
  );
};

export default SelectAmount;
