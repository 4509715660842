import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISenderDetails } from '../../types';

interface SenderState {
  senderDetails: ISenderDetails;
}

const initialState: SenderState = {
  senderDetails: {
    firstCardName: '',
    lastCardName: '',
    emailCard: '',
    mobileCardNumber: '',
    mobileCardNumberDisplay: '',
    cardNumber: '',
    expirationCardDate: '',
    securityCardCode: '',
    firstCardNameERR: '',
    lastCardNameERR: '',
    emailCardERR: '',
    mobileCardNumberERR: '',
    cardNumberERR: '',
    expirationCardDateERR: '',
    securityCardCodeIdERR: '',
  },
};

const { actions, reducer } = createSlice({
  name: 'SenderState',
  initialState,
  reducers: {
    setSender: (state, action: PayloadAction<ISenderDetails>) => {
      const { payload } = action;
      console.log('setSender payload:', action.payload);
      state.senderDetails = payload;
    },
  },
});

export const senderActions = {
  ...actions,
};

export const senderReducer = reducer;
