import { Container, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { AppClipsContextProvider, useAppClips } from './Context';
import Footer from './Footer';
import GoogleBanner from './GoogleBanner';
import Header from './Header';

const Content = () => (
  <>
    <Header mb={3} />
    <Container>
      <GoogleBanner />
    </Container>
    <Footer />
  </>
);

const Themed = () => {
  const { getThemeForMerchant } = useAppClips();
  return (
    <ThemeProvider theme={getThemeForMerchant()}>
      <CssBaseline />
      <Content />
    </ThemeProvider>
  );
};

const Page = (): React.ReactElement => (
  <AppClipsContextProvider>
    <Themed />
  </AppClipsContextProvider>
);

export default Page;
