import { BoxProps, Container } from '@material-ui/core';
import Flex from '../Common/Flex';
import { useAppClips } from './Context';

const Header = (props: BoxProps): React.ReactElement => {
  const { merchantContent } = useAppClips();
  return (
    <Container>
      <Flex alignItems="center" justifyContent="center" {...props} py={2.625}>
        <img src={merchantContent.data.brandLogoUrl} alt={merchantContent.name} style={{ maxHeight: 34 }} />
      </Flex>
    </Container>
  );
};
export default Header;
