import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GraphemeSplitter from 'grapheme-splitter';
import { ChangeEvent, FC, FocusEvent, useMemo, useState } from 'react';
import {
  ButtonSheet,
  Label,
  MobileNumber,
  SheetHeaderLabel,
  TextArea,
  TextFieldWithLabel,
} from '../../components/AppClip';
import Flex from '../../components/Common/Flex';
import {
  COUNTRY_CODE,
  FIELD_IDS,
  MAX_CHARACTERS,
  REGEX_PATTERNS,
  RecipientDetail as RecipientDetailConstants,
  SCREEN_IDS,
} from '../../constants';
import { RootState, recipientActions, screenActions, useAppDispatch, useAppSelector } from '../../store';
import { ValidateInputProps } from '../../types';
import { onChangeMobileNumber } from '../../utils/helpers';
import CheckIconImage from '../../images/icon/check-icon.png';

const { selectAmountScreen, reviewOrderScreen, scheduleDeliveryScreen } = SCREEN_IDS;
const { setRecipient } = recipientActions;
const { setScreen } = screenActions;
const { header } = RecipientDetailConstants;

const styles = {
  textAreaNote: (theme: Theme, isError: boolean, noRemainingChars: boolean) => ({
    ...theme.typography.caption,
    alignSelf: 'flex-end' as const,
    color: noRemainingChars ? theme.palette.error.main : theme.palette.common.black,
    fontSize: '12px',
    lineHeight: '16px',
    marginTop: isError ? '-1px' : '1em',
    marginBottom: 0,
    marginRight: 8,
    opacity: 0.6,
    textAlign: 'right' as const,
  }),
};

const RecipientDetails: FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { recipientDetails } = useAppSelector((state: RootState) => state.recipient);
  const { updating, fieldsIsFocus } = useAppSelector((state: RootState) => state.screen.screenStates);
  const {
    firstName,
    lastName,
    mobile,
    confirmMobileNumber,
    mobileNumberDisplay,
    confirmMobileNumberDisplay,
    giftMessage,
    giftMessageCharCounter,
    errors,
  } = recipientDetails;

  const { firstNameId, lastNameId, mobileNumberId, confirmMobileNumberId, giftMessageId } = FIELD_IDS.recipient;

  const fieldIdMapping = {
    [firstNameId]: 'firstName',
    [lastNameId]: 'lastName',
    [mobileNumberId]: 'mobile',
    [confirmMobileNumberId]: 'confirmMobileNumber',
    [giftMessageId]: 'giftMessage',
  };

  const onInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    const fieldValue = value.replace(/[^a-z .'-]/gi, '');

    const data = {
      ...recipientDetails,
      [fieldIdMapping[id]]: fieldValue,
    };

    if (id === mobileNumberId) {
      const formattedNumber = onChangeMobileNumber(value);
      data.mobile = formattedNumber.mobileNumberId;

      data.mobileNumberDisplay = `${COUNTRY_CODE} ${formattedNumber.mobileNumberDisplay}`;
      if (data.mobile !== recipientDetails.mobile) {
        data.confirmMobileNumber = '';
        data.confirmMobileNumberDisplay = '';
        data.errors = { ...errors, [confirmMobileNumberId]: '' };
      }
    }

    if (id === confirmMobileNumberId) {
      const formattedNumber = onChangeMobileNumber(value);
      data.confirmMobileNumber = formattedNumber.mobileNumberId;
      data.confirmMobileNumberDisplay = `${COUNTRY_CODE} ${formattedNumber.mobileNumberDisplay}`;
    }

    dispatch(setRecipient(data));
  };

  const onTextAreaInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    const splitter = new GraphemeSplitter(); //package that will handle the emoji unicode count as 1 character.
    if (MAX_CHARACTERS >= splitter.splitGraphemes(value).length) {
      const characterRemaining = MAX_CHARACTERS - splitter.splitGraphemes(value).length;

      const data = {
        ...recipientDetails,
        [fieldIdMapping[giftMessageId]]: value,
        giftMessageCharCounter: characterRemaining,
      };

      dispatch(setRecipient(data));
    }
  };

  const getFieldName = (id: string, isValidationError?: boolean) => {
    switch (id) {
      case firstNameId:
      case lastNameId:
        return id.replaceAll('-', ' ');

      case mobileNumberId:
      case confirmMobileNumberId:
        return isValidationError ? 'Australian mobile number' : 'mobile phone number';

      case giftMessageId:
        return 'a gift message for recipient';

      default:
        return id.replaceAll('-', ' ');
    }
  };

  const validation = (params: ValidateInputProps) => {
    const { id = '', value } = params;
    let fieldValue = value.replace(/[^a-z .'-]/gi, '');
    const fieldName = getFieldName(id);

    let regexStr: RegExp | string = '';

    if (id === mobileNumberId || id === confirmMobileNumberId) {
      regexStr = REGEX_PATTERNS.mobileNumberShort;
      fieldValue = value.replaceAll(' ', '');
    }

    let error = '';

    // validate confirm mobile number
    if (id === confirmMobileNumberId && mobile !== fieldValue) {
      error = 'Please enter same number as above';
    }

    if (!fieldValue.length) {
      error = `Please provide ${fieldName}.`;
    } else if (fieldValue.length < 2) {
      error = `Please enter a valid ${fieldName}.`;
    } else if (regexStr) {
      const regex = new RegExp(regexStr);
      const isValidInput = regex.test(fieldValue);
      if (!isValidInput) {
        error = `Please enter a valid ${getFieldName(id, true)}.`;
      }
    }

    const data = {
      ...recipientDetails,
      errors: {
        ...recipientDetails.errors,
        [id]: error,
      },
    };

    if (id === mobileNumberId && mobile !== fieldValue) {
      data.errors[confirmMobileNumberId] = '';
    }

    return error;
  };

  const validateFormInput = (id: string, value: string) => {
    dispatch(
      setRecipient({ ...recipientDetails, errors: { ...recipientDetails.errors, [id]: validation({ id, value }) } }),
    );
  };

  const validateForm = async () => {
    const _errors = {
      [firstNameId]: validation({ id: firstNameId, value: firstName || '' }),
      [lastNameId]: validation({ id: lastNameId, value: lastName || '' }),
      [mobileNumberId]: validation({ id: mobileNumberId, value: mobile || '' }),
      [confirmMobileNumberId]: validation({ id: confirmMobileNumberId, value: confirmMobileNumber || '' }),
      [giftMessageId]: validation({ id: giftMessageId, value: giftMessage || '' }),
    };

    await dispatch(setRecipient({ ...recipientDetails, errors: _errors }));
  };

  const stateFocused = (state = false, id = '') => {
    if (!fieldsIsFocus?.recipientDetails?.fields[id]) {
      //Guard
      dispatch(
        setScreen({
          fieldsIsFocus: {
            recipientDetails: {
              fields: {
                [firstNameId]: id === firstNameId ? state : false,
                [lastNameId]: id === lastNameId ? state : false,
                [mobileNumberId]: id === mobileNumberId ? state : false,
                [confirmMobileNumberId]: id === confirmMobileNumberId ? state : false,
                [giftMessageId]: id === giftMessageId ? state : false,
              },
            },
          },
        }),
      );
    }
  };

  const onFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id } = event.currentTarget;
    document?.getElementById(id)?.focus();
    switch (id) {
      case firstNameId:
      case lastNameId:
      case giftMessageId:
      case confirmMobileNumberId:
      case mobileNumberId:
        stateFocused(true, id);
        break;
      default:
        stateFocused(false, id);
        break;
    }
  };

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    const id = event.currentTarget.id;
    const value = event.currentTarget.value;

    validateFormInput(id, value);
    stateFocused(false, id);
  };

  const isConfirmMobileNumberDisabled = useMemo(() => {
    const mobileError = validation({ id: mobileNumberId, value: mobile || '' });
    return mobile === '' || mobileError !== '';
  }, [mobile, mobileNumberId]);

  const isConfirmMobileNumberValid = useMemo(() => {
    const _error = validation({ id: confirmMobileNumberId, value: confirmMobileNumber || '' });
    return confirmMobileNumber !== '' && _error === '';
  }, [confirmMobileNumber, confirmMobileNumberId]);

  const isMobileNumberValid = useMemo(() => {
    const _error = validation({ id: mobileNumberId, value: mobile || '' });
    return mobile !== '' && _error === '';
  }, [mobile, mobileNumberId]);

  const isContinueDisabled = useMemo(() => {
    const hasError = Object.values(errors).some((value) => value !== '');
    if (
      firstName === '' ||
      lastName === '' ||
      mobile === '' ||
      confirmMobileNumber === '' ||
      giftMessage === '' ||
      hasError
    ) {
      return true;
    }

    return false;
  }, [confirmMobileNumber, firstName, giftMessage, lastName, mobile, errors]);

  const onClickContinue = () => {
    validateForm();

    if (!isContinueDisabled) {
      dispatch(
        setScreen({ screen: updating ? reviewOrderScreen : scheduleDeliveryScreen, loading: false, updating: false }),
      );
    }
  };

  const onClickBack = () => {
    dispatch(
      setScreen({
        screen: selectAmountScreen,
        loading: false,
        updating: false,
        fieldsIsFocus: {
          recipientDetails: {
            fields: {
              [firstNameId]: false,
              [lastNameId]: false,
              [mobileNumberId]: false,
              [giftMessageId]: false,
            },
          },
        },
      }),
    );
  };

  return (
    <Flex
      style={{
        flexDirection: 'column',
      }}
    >
      <SheetHeaderLabel
        label={header}
        subLabel={updating ? '' : 'A Gift Card will be sent via SMS and linked to the recipients mobile number'}
      />
      <Flex
        style={{
          flexDirection: 'column',
        }}
      >
        <TextFieldWithLabel
          id={firstNameId}
          label="First name"
          htmlFor={firstNameId}
          placeholder="First name"
          type="text"
          maxLength={30}
          onInput={onInput}
          onFocus={onFocus}
          onBlur={onBlur}
          value={firstName}
          errorMessage={errors[firstNameId]}
          isFocusField={fieldsIsFocus?.recipientDetails?.fields[firstNameId]}
          autoFocus={fieldsIsFocus?.recipientDetails?.fields[firstNameId]} // autofocus will solve the issue with isFocusField in relation with blur not triggering
          required
        />
        <TextFieldWithLabel
          id={lastNameId}
          label="Last name"
          htmlFor={lastNameId}
          placeholder="Last name"
          type="text"
          maxLength={30}
          onInput={onInput}
          onFocus={onFocus}
          onBlur={onBlur}
          value={lastName}
          errorMessage={errors[lastNameId]}
          isFocusField={fieldsIsFocus?.recipientDetails?.fields[lastNameId]}
          autoFocus={fieldsIsFocus?.recipientDetails?.fields[lastNameId]}
          required
        />
        <MobileNumber
          id={mobileNumberId}
          label="Mobile number"
          htmlFor={mobileNumberId}
          placeholder="400 000 000"
          type="tel"
          value={mobileNumberDisplay.slice(4)}
          errorMessage={errors[mobileNumberId]}
          onInput={onInput}
          onFocus={onFocus}
          onBlur={onBlur}
          isFocusField={fieldsIsFocus?.recipientDetails?.fields[mobileNumberId]}
          autoFocus={fieldsIsFocus?.recipientDetails?.fields[mobileNumberId]}
          containerStyle={{
            marginTop: '-3px',
          }}
          icon={isMobileNumberValid ? CheckIconImage : false}
        />
        <MobileNumber
          id={confirmMobileNumberId}
          label="Confirm mobile number"
          htmlFor={confirmMobileNumberId}
          placeholder="400 000 000"
          type="tel"
          value={confirmMobileNumberDisplay.slice(4)}
          errorMessage={errors[confirmMobileNumberId]}
          onInput={onInput}
          onFocus={onFocus}
          onBlur={onBlur}
          isFocusField={fieldsIsFocus?.recipientDetails?.fields[confirmMobileNumberId]}
          autoFocus={fieldsIsFocus?.recipientDetails?.fields[confirmMobileNumberId]}
          containerStyle={{
            marginTop: '-3px',
          }}
          disabled={isConfirmMobileNumberDisabled}
          icon={isConfirmMobileNumberValid ? CheckIconImage : false}
        />
        <Flex
          style={{
            flexDirection: 'column',
            marginTop: '-3px',
          }}
        >
          <Label label="Gift message" htmlFor={giftMessageId} />
          <TextArea
            id={giftMessageId}
            placeholder="What do you want to say?"
            rows={4}
            cols={50}
            onInput={onTextAreaInput}
            onFocus={onFocus}
            isFocusField={fieldsIsFocus?.recipientDetails?.fields[giftMessageId]}
            autoFocus={fieldsIsFocus?.recipientDetails?.fields[giftMessageId]}
            errorMessage={errors[giftMessageId] || ''}
            value={giftMessage}
          />
          <p
            data-testid={'textarea-characters-remaining'}
            style={styles.textAreaNote(theme, !!errors[giftMessageId], giftMessageCharCounter === 0)}
          >
            {giftMessageCharCounter} characters remaining
          </p>
        </Flex>
      </Flex>
      <ButtonSheet
        label={updating ? 'Update' : 'Continue'}
        withBack={!updating}
        onPress={onClickContinue}
        onPressBack={onClickBack}
        backButtonStyle={{ marginTop: 24 }}
        buttonStyle={{ marginTop: 24 }}
        data-testid={'recipient-details-continue-button'}
        disabled={isContinueDisabled}
      />
    </Flex>
  );
};

export default RecipientDetails;
