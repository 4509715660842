/* eslint-disable @typescript-eslint/no-empty-function */
import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import LeaveDialog from '../components/LeaveDialog';
import ScheduleExpiredDialog from '../components/ScheduleExpiredDialog';
import { AppConfig, SCREEN_IDS } from '../constants';
import {
  customiseGiftActions,
  paymentActions,
  purchaseActions,
  recipientActions,
  scheduledDeliveryActions,
  screenActions,
} from '../store';
import { SEND_DATE_OPTIONS } from '../types';
const { setScreen } = screenActions;
const { resetRecipient } = recipientActions;
const { setSelectedAmount: setSelectedAmountRedux, resetUUID4 } = purchaseActions;
const { resetPayment } = paymentActions;
const { resetAnimation } = customiseGiftActions;
const { reset: resetScheduledDelivery } = scheduledDeliveryActions;
const { scheduleDeliveryScreen, reviewOrderScreen } = SCREEN_IDS;

type ContextValuesProps = {
  toggleLeaveDialog: (val?: boolean) => void;
  toggScheduleExpiredDialog: (val?: boolean) => void;
};

export const ConnectContext = createContext<ContextValuesProps>({
  toggleLeaveDialog: () => {},
  toggScheduleExpiredDialog: () => {},
});

export const useConnectContext = () => {
  const context = useContext(ConnectContext);
  if (context === undefined) {
    throw new Error('useConnectContext must be used within a ConnectContextProvider');
  }
  return context;
};

const ConnectContextProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch();
  const [isLeaveOpen, setLeaveOpen] = useState(false);
  const [isScheduleExpired, setScheduleExpired] = useState(false);
  const { setScheduledDelivery } = scheduledDeliveryActions;

  const resetSession = () => {
    batch(() => {
      dispatch(resetRecipient());
      dispatch(setSelectedAmountRedux(50));
      dispatch(resetPayment());
      dispatch(resetAnimation());
      dispatch(resetUUID4());
      dispatch(resetScheduledDelivery());
      dispatch(setScreen({ screen: SCREEN_IDS.selectAmountScreen }));
      setLeaveOpen(false);
      setScheduleExpired(false);
    });
  };

  const toggleLeaveDialog = (val?: boolean) => {
    if (AppConfig.DISABLE_LEAVE_DIALOG) return;
    if (val == null) {
      setLeaveOpen((p) => !p);
      return;
    }
    setLeaveOpen(val);
  };

  const toggScheduleExpiredDialog = (val?: boolean) => {
    if (val == null) {
      setScheduleExpired((p) => !p);
      return;
    }
    setScheduleExpired(val);
  };

  const handleLeaveConfirm = () => {
    resetSession();
  };

  const handleChangeConfirm = () => {
    dispatch(setScreen({ screen: scheduleDeliveryScreen, loading: false, updating: false }));
    setScheduleExpired(false);
  };

  const handleSendNow = () => {
    dispatch(
      setScheduledDelivery({ deliveryDate: new Date().toISOString(), deliveryOptionDate: SEND_DATE_OPTIONS.NOW }),
    );
    dispatch(setScreen({ screen: reviewOrderScreen, loading: false, updating: false }));

    setScheduleExpired(false);
  };

  return (
    <ConnectContext.Provider
      value={{
        toggleLeaveDialog,
        toggScheduleExpiredDialog,
      }}
    >
      {children}
      {isLeaveOpen && (
        <LeaveDialog
          open={isLeaveOpen}
          handleClose={() => toggleLeaveDialog(false)}
          handleConfirm={handleLeaveConfirm}
        />
      )}
      {isScheduleExpired && (
        <ScheduleExpiredDialog
          open={isScheduleExpired}
          handleClose={handleChangeConfirm}
          handleConfirm={handleSendNow}
        />
      )}
    </ConnectContext.Provider>
  );
};

export default ConnectContextProvider;
