import { TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { firebaseApp } from '../utils/Firebase';
import SimpleDialog from './SimpleDialog';
import Message from './Message';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  heading: {
    color: 'gray',
    fontSize: '2rem',
  },
  form: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    border: '1px lightgray solid',
    borderRadius: '0.3rem',
  },
  field: {
    marginBottom: '2rem',
  },
  button: {
    marginTop: '2rem',
  },
  phoneField: {
    marginBottom: '10px',
  },
}));

const Form = (): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState('An error occurred.');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const search = useLocation().search;
  const [token] = useState(new URLSearchParams(search).get('ct') ?? '');

  const classes = useStyles();

  const onMsgClose = () => {
    setMsgOpen(false);
    setOpen(false);
    setMsgText('');
    resetForm();
  };

  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setPhoneNumber('');
  };

  const submitForm = () => {
    if (token === '') {
      setMsgText('The form URL is invalid.');
      setMsgOpen(true);
    } else if (firstName === '' || lastName === '' || !(phoneNumber && phoneNumber.length > 3)) {
      setMsgText('All fields are required.');
      setMsgOpen(true);
    } else {
      sendPayload();
    }
  };

  const sendPayload = () => {
    setOpen(true);
    const generateLink = firebaseApp.functions('australia-southeast1').httpsCallable('generateLink');
    generateLink({
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      cardId: token ?? 'abc123',
    })
      .then(() => {
        setOpen(false);
      })
      .catch((error) => {
        console.log('There was an error! ', error.message);
        setMsgText(error.message === 'INTERNAL' ? 'An Error Occurred.' : error.message);
        setMsgOpen(true);
      });
  };

  return (
    <div className="Gift">
      <h1 className={classes.heading}>Send your Gift Card to...</h1>
      <form className={classes.form} noValidate autoComplete="off">
        <TextField
          className={classes.field}
          id="first-name"
          label="First name"
          variant="outlined"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          className={classes.field}
          id="last-name"
          label="Last name"
          variant="outlined"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <PhoneInput
          className={classes.phoneField}
          placeholder="Phone number"
          value={phoneNumber}
          defaultCountry="AU"
          onChange={(e) => setPhoneNumber(e ?? '')}
        />
        <Button className={classes.button} variant="contained" color="primary" onClick={submitForm}>
          Done
        </Button>
        <SimpleDialog open={open} />
        <Message open={msgOpen} text={msgText} onClose={onMsgClose} />
      </form>
    </div>
  );
};

export default Form;
