import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  customiseGiftReducer,
  merchantReducer,
  paymentReducer,
  purchaseReducer,
  recipientReducer,
  scheduledDeliveryReducer,
  screenReducer,
  senderReducer,
  tenantReducer,
} from './slices/index';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  merchant: merchantReducer,
  purchase: purchaseReducer,
  recipient: recipientReducer,
  screen: screenReducer,
  payment: paymentReducer,
  sender: senderReducer,
  customiseGift: customiseGiftReducer,
  tenant: tenantReducer,
  schedule: scheduledDeliveryReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
    // need to disable serial check
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
