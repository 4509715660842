import { FC } from 'react';

interface ICreditCard {
  primaryColor?: string;
  secondaryColor?: string;
  style: object;
}

const CreditCard: FC<ICreditCard> = ({ primaryColor, secondaryColor, style }) => {
  return (
    <div style={style}>
      <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 6C0 2.68629 2.68629 0 6 0H28C31.3137 0 34 2.68629 34 6V18C34 21.3137 31.3137 24 28 24H6C2.68629 24 0 21.3137 0 18V6Z"
          fill={secondaryColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.6 1.5H6.4C5.25514 1.5 4.48671 1.50117 3.89496 1.54952C3.32071 1.59643 3.04615 1.6802 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.6802 3.04615 1.59643 3.32071 1.54952 3.89496C1.50337 4.45976 1.5002 5.18552 1.50001 6.24591H32.5C32.4998 5.18552 32.4966 4.45976 32.4505 3.89496C32.4036 3.32071 32.3198 3.04615 32.2275 2.86502C31.9878 2.39462 31.6054 2.01217 31.135 1.77248C30.9539 1.6802 30.6793 1.59643 30.105 1.54952C29.5133 1.50117 28.7449 1.5 27.6 1.5ZM1.5 17.6V7.74591H32.5V17.6C32.5 18.7449 32.4988 19.5133 32.4505 20.105C32.4036 20.6793 32.3198 20.9539 32.2275 21.135C31.9878 21.6054 31.6054 21.9878 31.135 22.2275C30.9539 22.3198 30.6793 22.4036 30.105 22.4505C29.5133 22.4988 28.7449 22.5 27.6 22.5H6.4C5.25514 22.5 4.48671 22.4988 3.89496 22.4505C3.32071 22.4036 3.04615 22.3198 2.86502 22.2275C2.39462 21.9878 2.01217 21.6054 1.77248 21.135C1.6802 20.9539 1.59643 20.6793 1.54952 20.105C1.50117 19.5133 1.5 18.7449 1.5 17.6ZM0.435974 2.18404C0 3.03969 0 4.15979 0 6.4V17.6C0 19.8402 0 20.9603 0.435974 21.816C0.819467 22.5686 1.43139 23.1805 2.18404 23.564C3.03969 24 4.15979 24 6.4 24H27.6C29.8402 24 30.9603 24 31.816 23.564C32.5686 23.1805 33.1805 22.5686 33.564 21.816C34 20.9603 34 19.8402 34 17.6V6.4C34 4.15979 34 3.03969 33.564 2.18404C33.1805 1.43139 32.5686 0.819467 31.816 0.435974C30.9603 0 29.8402 0 27.6 0H6.4C4.15979 0 3.03969 0 2.18404 0.435974C1.43139 0.819467 0.819467 1.43139 0.435974 2.18404ZM5.22666 11.2427C4.81245 11.2427 4.47666 11.5785 4.47666 11.9927C4.47666 12.4069 4.81245 12.7427 5.22666 12.7427H11.7033C12.1175 12.7427 12.4533 12.4069 12.4533 11.9927C12.4533 11.5785 12.1175 11.2427 11.7033 11.2427H5.22666Z"
          fill={primaryColor}
        />
        <circle cx="28" cy="18" r="2" fill={primaryColor} />
        <circle cx="25" cy="18" r="2" fill={primaryColor} />
      </svg>
    </div>
  );
};

export default CreditCard;
