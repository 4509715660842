import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IScheduledDelivery, SEND_DATE_OPTIONS, SEND_OPTIONS } from '../../types';

interface ScheduledDeliveryState {
  scheduledDelivery: IScheduledDelivery;
}

const initialState: ScheduledDeliveryState = {
  scheduledDelivery: {
    deliveryOptionType: SEND_OPTIONS.EMAIL,
    deliveryOptionDate: SEND_DATE_OPTIONS.NOW,
    selectedTimeZoneId: undefined,
    deliveryDate: undefined,
    emailAddress: undefined,
    mobileNumber: undefined,
    mobileNumberDisplay: undefined,
  },
};

const { actions, reducer } = createSlice({
  name: 'ScheduledDeliveryState',
  initialState,
  reducers: {
    setScheduledDelivery: (state, action: PayloadAction<IScheduledDelivery>) => {
      const { payload } = action;
      const { deliveryDate } = payload;
      if (Boolean(deliveryDate) && typeof deliveryDate === 'object') {
        payload.deliveryDate = (deliveryDate as Date).toISOString();
      }
      state.scheduledDelivery = payload;
    },
    reset: () => initialState,
  },
});

export const scheduledDeliveryActions = {
  ...actions,
};

export const scheduledDeliveryReducer = reducer;
