import { Button, Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';

interface MsgArgs {
  text: string;
  onClose: () => void;
  open: boolean;
}

const Message = ({ text, onClose, open }: MsgArgs): React.ReactElement => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">There was an issue...</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <Button onClick={handleClose}>OK</Button>
    </Dialog>
  );
};

export default Message;
