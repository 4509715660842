import { FC } from 'react';

import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Flex from '../Common/Flex';
import { ButtonSheet } from '.';
import { Containers } from '../../styles/AppClip';
interface IErrorModalComponent {
  label?: string;
  message?: string;
  buttonLabel?: string;
  open?: boolean;
  onPress: () => void;
}
const useStyles = makeStyles((theme) => ({
  // Maybe style the scrollbar in the future
  root: {
    '&::-webkit-scrollbar': {
      width: '0px',
    },
    '&::-webkit-scrollbar-track': {},
    '&::-webkit-scrollbar-thumb': {},
  },
}));

const styles = {
  label: (theme: Theme) => ({
    fontSize: 17,
    letterSpacing: 'normal',
    lineHeight: '22px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    textAlign: 'center' as const,
  }),
  messsage: (theme: Theme) => ({
    fontSize: 16,
    letterSpacing: 'normal',
    lineHeight: '22px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    textAlign: 'center' as const,
    marginTop: '2px',
    marginBottom: '3px',
  }),
};

export const ErrorModalComponent: FC<IErrorModalComponent> = ({
  label = '',
  message = '',
  buttonLabel = '',
  open = false,
  onPress,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Modal open={open}>
      <Flex
        style={{
          ...Containers.column,
          height: '200px',
          width: 'auto',
          maxWidth: '300px',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          margin: 'auto',
          borderRadius: '10px',
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Flex
          className={classes.root}
          style={{
            flexDirection: 'column',
            overflowY: 'scroll',
          }}
        >
          <span style={styles.label(theme)}>{label}</span>
          <span style={styles.messsage(theme)}>{message}</span>
        </Flex>
        <Flex>
          <ButtonSheet buttonStyle={{ marginTop: 10, marginBottom: '3px' }} label={buttonLabel} onPress={onPress} />
        </Flex>
      </Flex>
    </Modal>
  );
};
